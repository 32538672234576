.connection-heading {
  font-weight: 700;
  font-size: 18px;
}
.content-section .bg-color-grey {
  background-color: #d1d6f3;
  padding: 6px 16px;
  border-radius: 6px;
  opacity: 0.2;
}
.invited-button button {
  opacity: 0.5;
}
.invited-button button:hover {
  background-color: var(--text-color-primary);
  opacity: 0.5;
}
.network-container .ant-row-flex {
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
}

div .ant-divider-vertical {
  height: 40px !important;
}
.network-heading {
  font-weight: 700;
  font-size: 18px;
}
.network-container .general-hospital {
  color: var(--text-color-primary);
  font-size: 14px;
  font-weight: normal;
}
.doctor-image-wrap {
  position: relative;
}
.doctor-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #d1d6f3;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.doctor-image .name-initials {
  font-weight: 700;
  font-size: 16px;
  color: white;
}
.patient-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0;
  padding: 0;
}
.doctor-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ant-select-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.doctor-list li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 9px 5px;
  border-radius: 2px;
  margin: 0;
}
.doctor-list li:hover {
  background-color: rgba(209, 214, 243, 0.2);
}

.doctor-list li div p {
  margin: 0;
  padding: 0 0 0 8px;
  font-size: 14px;
  color: #000;
  width: calc(100% - 35px);
}
.ant-select-dropdown-menu-item.network-dropdown-list-group {
  padding: 0;
  margin: 0 6px;
}
.doctor-list li div p:nth-child(2) {
  color: var(--text-color-dark);
}
.network-container p {
  color: var(--text-color-dark);
  font-size: 14px;
  margin: 0;
  font-weight: 700;
  padding: 0;
  align-items: center;
  -webkit-font-smoothing: antialiased;
}
.network-container p.text {
  color: #000;
  font-size: 14px;
  font-weight: 300;
  margin: 0 0 4px 0;
  padding: 0;
}
.network-container .box_with_show_more p.text {
  max-width: 57%;
}

.network-container span {
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  padding: 0;
}
.invited-button {
  width: 200px;
}
.invited-button button {
  width: 150px;
}

.network-container .text-result {
  font-size: 14px;
  padding: 8px 00px;
  color: #078dff;
  font-weight: 700;
  cursor: pointer;
}
.network-container .people-you-know {
  font-size: 18px;
  margin-top: 52px;
  margin-bottom: 17px;
  padding: 8px 0px;
  color: #000000;
  font-weight: 600;
}

.dot::before {
  content: "•";
  width: 2px;
  height: 2px;
  padding: 0 6px;
  justify-content: center;
  align-items: center;
}
.bg-gray-color {
  background-color: #ecedef;
  padding: 4px 8px;
}
.referrals-bg-gray::before {
  content: "K";
  width: 20px;
  height: 20px;
  position: absolute;
  left: 25px;
  font-size: 25px;
  top: 0px;
  color: var(--text-color-primary);
  font-weight: bold;
}
.referrals-bg-gray {
  background-color: var(--app-background-primary);
  text-align: center;
  opacity: 0.7;
  padding: 8px 0;
  border-radius: 4px;
  padding-left: 3px;
}
.referrals-bg-gray > p {
  opacity: 1;
}

.network-container .full-width button {
  width: 100%;
}
.network-container .university-icon {
  display: inline-block;
  vertical-align: middle;
  width: 17px;
  height: 15px;
  /* background-image: url("../../images/icons/university-icon.svg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: translateX(-5px);
  position: absolute;
  left: 5px;
  top: 4px;
}
.network-container .graduation-icon {
  display: inline-block;
  width: 15px;
  height: 12px;
  margin-left: 2px;
  margin-right: 5px;
  /* background-image: url("../../images/icons/graduation-icon.svg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.network-container .kulcare-icon {
  display: inline-block;
  width: 10px;
  height: 17px;
  margin-right: 5px;
  /* background-image: url("../../images/icons/kulcare-icon.svg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  top: 2px;
}
.network-container .hospital-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  /* background-image: url("../../images/icons/hospital.svg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.network-search-with-dropdown input[type="text"] {
  border: none;
}
.network-search-with-dropdown .anticon-search {
  margin-right: -35px;
}
.network-list-view .ant-select-dropdown-menu-item-group-title {
  font-size: 14px;
  font-weight: 700;
  padding: 0 0 0 15px;
  margin: 0;
  color: #000;
}
.network-list-view .ant-select-dropdown-menu-item {
  margin: 0 2px;
  padding: 5px 5px;
}
.network-select-icon .ant-select-arrow {
  font-size: 12px;
  margin-top: -6px;
  color: #707070;
}
.doctor_collegues_wrap {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.network_doctor_collegues_wrap {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.kc-doc-badge {
  max-width: 186px;
  padding: 7px !important;
  margin-bottom: 0 !important;
  border-radius: 4px;
}
.pending_invites_wrap {
  margin-top: 50px;
}
.doctor-name-wrap {
  display: flex;
}
.doctor-name-wrap .content-section {
  margin-left: 10px;
}
.content-section {
  font-size: 14px;
}
.truncated {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.box_with_show_more {
  position: relative;
  display: flex;
}
.box_with_show_more input {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
.box_with_show_more input:checked + p {
  -webkit-line-clamp: unset;
}
.box_with_show_more input:checked ~ label,
.box_with_show_more p:not(.truncated) ~ label {
  display: none;
}
.text-with-pl {
  position: relative;
  padding-left: 22px !important;
}
.box_with_show_more label[role="button"] {
  padding-left: 5px;
}
.nw-search-width {
  width: 412px;
}
.network-filter-row {
  display: flex;
  flex-wrap: wrap;
}
.nw-filter-block {
  width: 132px;
  margin-right: 8px;
}
.nw-filter-icon {
  transform: translateY(4px);
}
.nw-filter-mobile-view {
  display: none;
}
.nw-filter-web-view {
  display: block;
}
.nw-pg-flex-heading {
  display: flex;
  justify-content: space-between;
}
.tele_doc_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
  border-bottom: 1px solid rgb(209 214 243 / 50%);
  height: 55px;
}
.tele_doc_item:not(.header):hover {
  box-shadow: 0 2px 8px 0 rgba(189, 194, 220, 0.28);
}
.tele_doc_list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.pr-10 {
  padding-right: 10px;
}
.col-30 {
  width: 30%;
}
.col-25 {
  width: 25%;
}
.col-20 {
  width: 20%;
}
.language-label {
  background-color: rgba(7, 141, 255, 0.1);
  padding: 1px 8px 2px;
  border-radius: 4px;
  margin-right: 5px;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
}
.doctor-listing-inner-wrap {
  padding: 10px 15px 20px 15px;
}
.doctor-listing-inner-wrap .search-nav {
  padding: 5px 0 15px 0;
}
.doctor-listing-inner-wrap .result-found {
  padding-left: 9px;
  align-items: baseline;
}
.vertical-divider {
  border-right: 1px solid var(--light-grey-color);
  height: 35px;
}
.vertical-divider.abs-pos {
  position: absolute;
  left: 36%;
  top: -15px;
}
.col-10 {
  width: 10%;
}
.col-15 {
  width: 15%;
}
.rel-pos {
  position: relative;
}
.width-70 {
  width: 70px;
}
.col-80 {
  width: 80%;
}
.col-40 {
  width: 40%;
}
.live-user-info {
  display: inline-block;
  text-align: center;
  padding: 0px 12px 0px 20px !important;
  position: relative;
  border-radius: 3px;
}
.live-user-info::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #1fc9c1;
  position: absolute;
  left: 10px;
  top: 9px;
}
.hide-text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  max-width: 230px;
}
.name-initials.sm {
  font-size: 12px;
}
.live-dot {
  height: 10px;
  position: relative;
  width: 10px;
  border-radius: 100%;
  display: none;
}
.live-dot.online {
  background-color: #1fc9c1;
}
.live-dot.offline {
  background-color: white;
  border: 1px solid var(--light-grey-color);
}
.live-dot.online::after {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  border-radius: 100%;
  background-color: inherit;
  -webkit-animation: 5s ease-out signal infinite;
  animation: 5s ease-out signal infinite;
  opacity: 0.5;
}
.live-dot-box {
  position: absolute;
  right: -6px;
  bottom: 0px;
}
.live-dot-box.start {
  left: 0px;
  right: auto;
  top: 6px;
  bottom: auto;
}
.pl-20 {
  padding-left: 20px;
}
/* skeleton css */

.tele-card .header {
  display: flex;
  align-items: center;
}

.tele-card .header .img {
  position: relative;
  width: 70px;
  height: 70px;
  background: #d9d9d9;
  border-radius: 50%;
  overflow: hidden;
}

.tele-card .details span {
  position: relative;
  display: block;
  background: #d9d9d9;
  border-radius: 10px;
  overflow: hidden;
}

.tele-card .details .name {
  width: 100px;
  height: 15px;
}

.tele-card .details .about {
  width: 150px;
  height: 15px;
  margin-top: 10px;
}
.tele-card .header .img {
  position: relative;
  width: 40px;
  height: 40px;
  background: #d9d9d9;
  border-radius: 50%;
  overflow: hidden;
}
.tele-card .header .img::before,
.details span::before,
.content .line::before,
.btns .btn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    #d9d9d9 0%,
    #f5f5f5 20%,
    #d9d9d9 40%,
    #d9d9d9 100%
  );
  background-size: 450px 400px;
  background-repeat: no-repeat;
  animation: shimmer 1.4s linear infinite;
}
.tele-skl-btn {
  position: relative;
  width: 100%;
  height: 28px;
  background: #d9d9d9;
  border-radius: 3px;
  overflow: hidden;
}
.tele-skl-btn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    #d9d9d9 0%,
    #f5f5f5 20%,
    #d9d9d9 40%,
    #d9d9d9 100%
  );
  background-size: 450px 400px;
  background-repeat: no-repeat;
  animation: shimmer 1.4s linear infinite;
}
.ml-9 {
  margin-left: 9px;
}
.grey-color {
  color: #707070;
}
.location-text {
  max-width: calc(100% - 62px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}
.col-50 {
  width: 50%;
}
.tele_doc_item.header {
  padding: 18px 10px;
}
.right-bdr {
  position: relative;
  height: 44px;
  display: flex;
  /* align-items: center; */
  padding-right: 32px;
}
.right-bdr:after {
  content: "";
  width: 0.5px;
  height: 20px;
  background-color: var(--light-grey-color);
  position: absolute;
  top: 15px;
  right: 21px;
}
.max-2-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.max-1-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.for-sm-screen {
  display: none;
}
.for-desktop-screen {
  display: block;
}
.cursor-pointer {
  cursor: pointer;
}
.link-type {
  cursor: pointer;
}
@keyframes shimmer {
  0% {
    background-position: -450px 0px;
  }
  100% {
    background-position: 450px 0px;
  }
}
@keyframes signal {
  20%,
  100% {
    opacity: 0;
    transform: scale(4);
  }
}
@media (max-width: 1240px) {
  .network-container .box_with_show_more p.text {
    max-width: 67%;
  }
}
@media (max-width: 992px) {
  .network-container .ant-row-flex {
    flex-direction: column;
  }
  .network-container .ant-col {
    width: 100%;
    text-align: center;
  }
  .network-container .doctor-image {
    margin-left: auto;
    margin-right: auto;
  }
  .network-container .d-flex {
    justify-content: center;
  }
  .network-container p {
    margin-bottom: 15px !important;
  }
  .network-container .full-width button {
    width: 200px;
    margin-top: 20px;
  }
  .doctor-name-wrap .content-section {
    margin-left: 0;
  }
  .network-container .box_with_show_more p.text {
    max-width: 80%;
  }
  .box_with_show_more {
    justify-content: center;
  }
  .nw-filter-block {
    width: 42%;
    margin-right: 8px;
    margin-bottom: 10px;
  }
  .doctor-image {
    width: 30px;
    height: 30px;
  }
  .doctor-image-wrap {
    margin-right: 10px;
  }
  .for-sm-screen {
    display: block;
  }
  .tele_doc_item {
    height: auto;
    padding: 8px 10px;
  }
  .for-desktop-screen {
    display: none;
  }
  .col-20 {
    width: 30%;
  }

  .col-15:not(.buttons-section) {
    width: 20%;
  }
}
@media (max-width: 800px) {
  .right-bdr::after {
    display: none;
  }
  .right-bdr {
    padding-right: 0;
    height: auto;
  }
}
@media (max-width: 768px) {
  .col-15.buttons-section {
    width: 100%;
  }
  .tele_doc_item.header {
    display: none;
  }
  .col-15:not(.buttons-section),
  .col-20 {
    width: 100%;
    justify-content: center;
  }
  .col-40 {
    width: 100%;
  }
  .doctor-image-wrap {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .col-15.buttons-section {
    width: 100px;
  }
  .filter-block.sz-40 {
    width: 100%;
  }
  .doctor-name-wrap .content-section {
    margin-left: 10px;
  }
  .tele_doc_item {
    padding: 8px 0px;
  }
}
@media (max-width: 680px) {
  .nw-filter-block {
    width: 100%;
    margin-right: 0;
  }
  .nw-search-width {
    width: 100%;
  }
  .network-filter-row .ant-btn {
    padding-left: 0;
  }
  .nw-filter-mobile-view {
    display: block;
  }
  .nw-filter-web-view {
    display: none;
  }
  .nw-filter-mobile-view a {
    position: relative;
  }
  .nw-filter-indicator {
    width: 10px;
    height: 10px;
    background-color: var(--text-color-red);
    position: absolute;
    right: -3px;
    top: -2px;
    border-radius: 10px;
  }
}
