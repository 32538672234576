@import "./variables.scss";
.btn {
  border: none;
  padding: 5px 25px;
  background-color: white;
  height: $btn-normal-height;
  line-height: $btn-normal-line-height;
  color: $text-color-dark;
  border-radius: $controls-radius;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s 0s;
  font-size: 14px;
  &:hover {
    background-color: $border-color;
  }
  &.large {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  &.block {
    width: 100%;
  }
}
.primary {
  background-color: $primary-color;
  color: white;
  &:hover {
    background-color: $primary-color-hover;
  }
}
.secondary {
  background-color: $purple-color;
  color: white;
  &:hover {
    background-color: $purple-color-hover;
  }
}
.small {
  height: $btn-small-height;
  line-height: $btn-small-line-height;
  padding: 5px 20px;
}
.link{
  color: var(--text-color-primary);
  &:hover{
    background-color: transparent;
  }
}
.ant-btn-primary[disabled]{
  background-color: var(--text-color-primary) !important;
  opacity: 0.6;
  color: white;
  pointer-events: none;
}