@import url("https://fonts.googleapis.com/css?family=Muli:400,400i,600,700,900&display=swap");
:root {
  --card-shadow: 0 1px 2px rgba(189, 194, 220, 0.15);
  --btn-border-radius: 4px;
  --controls-border-radius: 3px;
  --light-grey-color: #d1d6f3;
  --purple-color: #545df9;
  --background-color-primary: #078dff;
}

* {
  font-family: "Muli", sans-serif;
}
.appointment_main_wrapper {
  width: 455px;
  margin: 0 auto;
}
.appt_wrapper {
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  position: relative;
}
.font-size-20 {
  font-size: 20px;
}
.font-weight-bold {
  font-weight: 700;
}
.appointment-type-tabs {
  display: flex;
  height: 32px;
  margin-top: 15px;
  width: 100%;
}
.appointment-type-tabs a {
  text-align: center;
  flex: 1;
  color: #078dff;
  border-top: 1px solid #078dff;
  border-bottom: 1px solid #078dff;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: background-color 0.15s, color 0.15s;
  font-size: 14px;
}
.appointment-type-tabs a:last-child {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid #078dff;
}
.appointment-type-tabs a:first-child {
  border-radius: var(--btn-border-radius) 0 0 var(--btn-border-radius);
  border-left: 1px solid #078dff;
}
.appointment-type-tabs a.active {
  color: white;
  font-weight: 600;
  background-color: #078dff;
}
.appointment-type-tabs a.disabled {
  pointer-events: none;
}
.form_field {
  width: 100%;
  margin-bottom: 15px;
}
.appt-location-select {
  margin-top: 16px;
}
.form_field label {
  display: block;
  font-size: 14px;
  margin-bottom: 6px;
  font-weight: 700;
  color: #353949;
}
.select-wrap input {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  height: 35px;
  border: 1px solid #d1d6f3;
  border-radius: 0.25em;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
}
.appt-booking-amount {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.appt-calender-wrapper {
  margin-top: 15px;
}
.time-slots-section {
  scroll-behavior: smooth;
}

.time-slots-section {
  max-height: 280px;
  overflow-y: auto;
}
.time-slots-block {
  padding-top: 15px;
}
.slot-day {
  font-weight: 600;
}
.time-slots-wrapper {
  /* padding: 10px 0; */
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  max-height: 206px;
  overflow-y: auto;
  margin-bottom: 20px;
}
.appt-time {
  width: 24%;
  padding: 0;
  height: 26px;
  border: 1px solid #d1d6f3;
  text-align: center;
  border-radius: var(--btn-border-radius);
  font-size: 12px;
  color: #353949;
  margin-bottom: 5px;
  flex-basis: calc(25% - 8px);
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s 0s;
  cursor: pointer;
}
.full_width {
  width: 100%;
}
.more_action_wrap {
  display: flex;
  align-items: center;
  margin-top: 17px;
  font-size: 14px;
}
.back_to_slot {
  font-size: 14px;
  display: inline-block;
  position: relative;
  padding-left: 10px;
  color: #078dff;
  font-weight: 700;
}
.add_patient_info_wrapper {
  margin-top: 15px;
  border-top: 1px solid #d1d6f3;
  padding-top: 15px;
}
.patient_info_wrapper p {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
}
.field_sep {
  width: 10px;
}

.otp_input_box {
  justify-content: space-between;
}
.otp_input_box input {
  width: 100% !important;
  height: 40px;
  border-radius: var(--controls-border-radius);
  inset: none;
  background: white;
  border: 1px solid var(--light-grey-color);
  box-shadow: none !important;
  outline: none !important;
  font-size: 14px;
}
.otp_input_box input:focus {
  border: 1px solid var(--text-color-primary);
}
.dob-calendar-popper .react-datepicker__triangle {
  border-bottom: 1px solid #f0f0f0;
  top: 0;
  bottom: auto;
  border-top: none;
  margin-bottom: 0;
  margin-top: -8px;
}
.dob-calendar-popper .react-datepicker__triangle:before {
  top: -1px;
  border-bottom-color: #aeaeae;
  bottom: auto;
  border-top-color: transparent;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  background-color: var(--purple-color);
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: var(--background-color-primary);
}
.appt-time.selected {
  border: 1px solid rgb(7, 141, 255);
  color: #078dff;
}
.form_field input {
  height: 35px;
  background-color: rgb(255, 255, 255);
  box-shadow: none;
  border-radius: 2px;
  font-size: 14px;
  line-height: 35px;
  color: rgb(53, 57, 73);
  width: 100%;
  padding: 0px 11px;
  border: 1px solid rgb(209, 214, 243);
  display: block;
}
.half_width {
  width: 49%;
}
.mt-20 {
  margin-top: 20px;
}
.no-slot-block {
  padding: 0;
  height: 32px;
  border: 1px solid #d1d6f3;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 5px;
  margin: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s 0s;
  color: red;
  width: 98%;
  margin: 0 auto;
}
.appt-calender-wrapper .react-datepicker__month-container {
  float: none;
  padding: 10px 16px;
}
.appt-calender-wrapper .react-datepicker {
  width: 100%;
  box-shadow: -1.61674px 2.42511px 14.5507px 1.61674px #ebeef1;
  border-radius: 4px;
  border: 1px solid transparent;
  z-index: 0;
}
.appt-calender-wrapper .react-datepicker__header {
  background-color: white;
  border-bottom: 1px solid #d6dbf4;
}
.appt-calender-wrapper .react-datepicker__navigation-icon::before {
  border-width: 1px 1px 0 0;
  height: 6px;
  width: 6px;
  border-color: #999ebc;
  top: 20px;
}
.appt-calender-wrapper .react-datepicker__current-month,
.appt-calender-wrapper .react-datepicker-time__header,
.appt-calender-wrapper .react-datepicker-year-header {
  font-size: 14px;
  font-weight: 700;
  color: #353949;
}
.appt-calender-wrapper .react-datepicker__day-name,
.appt-calender-wrapper .react-datepicker__day,
.appt-calender-wrapper .react-datepicker__time-name {
  width: 28px;
}
.appt-calender-wrapper .react-datepicker__day--selected,
.appt-calender-wrapper .react-datepicker__day--selected:hover,
.appt-calender-wrapper .react-datepicker__day--keyboard-selected {
  background-color: #078dff;
  border-radius: 50%;
}
.appt-calender-wrapper .react-datepicker__day:hover,
.appt-calender-wrapper .react-datepicker__month-text:hover,
.appt-calender-wrapper .react-datepicker__quarter-text:hover,
.appt-calender-wrapper .react-datepicker__year-text:hover {
  border-radius: 50%;
}
.appt-calender-wrapper .react-datepicker__day-name,
.appt-calender-wrapper .react-datepicker__day,
.appt-calender-wrapper .react-datepicker__time-name {
  margin: 0.3rem 0.6rem;
}
.appt-calender-wrapper .react-datepicker__day-name {
  font-size: 12px;
  color: var(--text-color-dark);
  font-weight: 700;
  text-transform: uppercase;
}

.react-select-container.compact-input .react-select-kc__control {
  height: 35px !important;
  min-height: 35px !important;
}
.react-select-container.compact-input .react-select-kc__value-container {
  height: 34px !important;
}
.form_field input:focus {
  border: 1px solid #078dff;
  outline: none;
  box-shadow: none;
}
.form_field input:focus-visible {
  outline: none;
}
.info_col_heading {
  margin: 0;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 14px;
}
.label_with_flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.blue-text {
  color: #078dff;
}
.font-size-14 {
  font-size: 14px;
}
.patient_list_ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 30px;
  max-height: 180px;
  overflow-y: auto;
}
.patient_list_ul .patient-list-item {
  padding: 10px 10px 15px 0;
  border-bottom: 1px solid #d1d6f3;
  cursor: pointer;
  min-height: auto !important;
}
.patient_list_ul .patient-list-item:hover {
  background-color: #f8faff;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.patient_list_ul .patient-list-item.selected {
  background-color: #f8faff;
}
.patient_list_ul .patient-name {
  font-size: 16px;
  font-weight: 700;
}
.patient_list_ul .patient-other-info {
  font-size: 14px;
  font-weight: 400;
}
.div_or_text {
  margin: 0;
  padding: 25px 0 5px;
  font-size: 14px;
  font-weight: 700;
}
.space_between {
  justify-content: space-between;
}
.row_with_cols {
  display: flex;
}
.patient_detail_main_wrapper {
  background-color: #f9fcff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 65px;
  margin-top: 10px;
}

.patient_detail_wrapper {
  width: 435px;
  min-height: 435px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  border-radius: 6px;
  padding: 30px 20px;
  margin-top: 20px;
  border: 1px solid transparent;
  box-shadow: var(--card-shadow);
}
.confirmed_appt_text {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
}
.apt-confirm {
  margin-top: 20px;
  background-color: var(--sky-light-blue-color);
  padding: 20px;
}
.apt-date-time-top {
  margin-bottom: 15px;
}
.apt-confirm-box .apt-time {
  color: var(--text-color-primary);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
}
.apt-confirm-box .apt-date {
  font-size: 14px;
  font-weight: 400;
}
.apt-confirm-box .apt-block {
  display: flex;
  margin-bottom: 8px;
}
.apt-confirm-box .apt-block:last-child {
  margin-bottom: 0;
}
.apt-confirm-box .apt-block-icon {
  background-repeat: no-repeat;
  margin-right: 10px;
}
.apt-confirm .apt-block-label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
}
.apt-block-icon.doctor {
  background-image: url("../../../images/speciality-empty.svg");
  width: 14px;
  height: 14px;
  background-size: contain;
  background-position: center top;
  transform: translateY(4px);
}
.apt-block-icon.inperson {
  background-image: url("../images/users-dark.svg");
  width: 12px;
  height: 12px;
  background-position: center top;
  background-size: contain;
  transform: translateY(5px);
  margin-right: 14px;
}
.apt-block-icon.location {
  background-image: url("../images/location-dark.svg");
  transform: translateY(5px);
}
.apt-block-icon.video {
  transform: translateY(5px);
  background-image: url("../../../images/appointment-empty.svg");
  width: 15px;
  height: 15px;
  background-size: contain;
  background-position: center top;
}
.apt-block-icon.fees {
  width: 12px;
  height: 12px;
  background-image: url("../images/currency-rupee.svg");
  background-size: contain;
  background-position: center top;
  transform: translate(1px, 6px);
}
.apt-confirm .apt-block-label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
}
.apt-block-content {
  flex: 1;
  font-size: 14px;
}
.apt-block-text {
  color: #353949;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
}
.apt-block-text-grey {
  line-height: 20px;
  font-weight: 300;
}
.hor_divider_with_bg {
  width: 100%;
  height: 5px;
  background-color: #f3f6fb;
}
.whats_next_wrapper {
  margin-top: 20px;
}
.whats_next_desc {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 300;
}
.mb-20 {
  margin-bottom: 20px;
}
.ba_success_icon_box {
  text-align: center;
  width: 40px;
  margin: 0 auto 10px;
}
.ba_success_icon_box img {
  width: 100%;
}
.whats_next_steps {
  margin-top: 0px;
  margin-left: 0px;
  font-weight: 300;
  padding-left: 0px;
  margin-bottom: 20px;
}
.whats_next_steps > div {
  font-size: 14px;
  font-weight: 400;
  padding-left: 0;
  text-align: center;
}
.whats_next_steps li {
  margin-bottom: 5px;
}
.whats_next_steps li:last-child {
  margin-bottom: 0;
}
.whats_next_wrapper h4,
.whats_next_wrapper h2 {
  font-size: 14px;
  margin: 0;
  text-align: center;
}
.name_greeting {
  font-size: 24px;
  text-align: center;
}
.hide {
  display: none !important;
}
/* skeleton style: start */
.skl-card-body {
  overflow: hidden;
}
.skl-card-img {
  padding-bottom: 56.25%;
  position: relative;
  margin-top: 10px;
}
.skl-card-img img {
  position: absolute;
  width: 100%;
}
.skl-card-title {
  font-size: 1.25rem;
  line-height: 1.33;
  font-weight: 700;
}
.skl-card-title.skl-skeleton {
  min-height: 28px;
  border-radius: 4px;
}
.skl-card-intro {
  margin-top: 0.75rem;
  line-height: 1.5;
}
.skl-card-intro.skl-skeleton {
  min-height: 72px;
  border-radius: 4px;
}
.skl-skeleton {
  position: relative;
  background-color: #f2f2f2;
}
.skl-skeleton:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 2s infinite;
  content: "";
}
.list-type-skeleton-wrap {
  justify-content: space-between;
}
.list-type-skeleton-wrap .skl-skeleton.list-item {
  width: 78px;
  height: 44px;
  margin: 4px 0;
  margin-bottom: 6px;
}
.list-type-skeleton-wrap .skl-skeleton.list-title {
  width: 78px;
  min-height: 44px;
  margin-bottom: 10px;
}
.skl_wrapper {
  width: 100%;
}
.inplace-edit-widget-wrap .appt_wrapper {
  padding: 0;
}
.mt-12 {
  margin-top: 12px !important;
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}
.creatable-select-container .css-yk16xz-control {
  border-color: var(--light-grey-color);
  background-color: var(--app-background-primary);
}
.creatable-select-container .css-1okebmr-indicatorSeparator {
  background-color: var(--light-grey-color);
}
.creatable-select-container .css-tlfecz-indicatorContainer {
  color: var(--light-grey-color);
}
.creatable-select-container .css-1pahdxg-control,
.creatable-select-container .css-1pahdxg-control:hover {
  box-shadow: none;
  border-color: var(--purple-color);
}
.creatable-select-container .css-26l3qy-menu {
  transform: translateX(1px);
}
.creatable-select-container .css-yt9ioa-option,
.creatable-select-container .css-1n7v3ny-option {
  padding: 3px 12px;
}
.creatable-select-container .css-yk16xz-control {
  border-radius: 6px;
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.css-1wa3eu0-placeholder {
  color: var(--bluey-grey) !important;
  font-size: var(--label-font-size);
  background-color: white;
}
.css-1uccc91-singleValue {
  font-size: var(--label-font-size);
}
.css-b8ldur-Input {
  margin: 0 !important;
}
.appt-calender-wrapper .react-datepicker__day {
  font-size: 12px;
  font-weight: 400;
}
.av-availability-section {
  display: flex;
  justify-content: space-between;
}
.available-time-slot {
  border: 1px solid var(--bluey-grey);
}
.slot_main_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.slot_wrapper {
  width: 32.5%;
}
.day-slot-wrapper {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
}
.appt-slot {
  width: 78px;
  padding: 0;
  min-height: 44px;
  border: 1px solid #d1d6f3;
  text-align: center;
  border-radius: var(--btn-border-radius);
  font-size: 12px;
  color: #353949;
  margin: 4px 0;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.3s 0s;
  cursor: pointer;
  text-transform: capitalize;
}
.appt-slot.selected {
  border: 1px solid rgb(7, 141, 255);
  color: var(--text-color-primary);
}
.discounted-slot {
  font-size: 10px;
  padding: 2px;
  display: flex;
  align-items: center;
}

.discounted-slot .off {
  color: var(--text-color-red);
  font-weight: 700;
  border-radius: 3px;
  margin-left: 4px;
  text-transform: none;
}
.app-slot-heading {
  font-weight: 700;
  font-size: 12px;
  text-align: center;
}
.greyed-out {
  background-color: var(--app-background-primary);
  pointer-events: none;
  border: 1px solid var(--app-background-primary);
  color: rgb(53 57 73 / 50%);
}
.text-capitalize {
  text-transform: capitalize;
}
.mr-22 {
  margin-right: 22px !important;
}
.ml-2 {
  margin-left: 2px;
}
.loc-icon-block {
  margin-right: 8px;
}
.loc-icon-block svg {
  transform: translate(-2px, 2px);
}
.mb-0 {
  margin-bottom: 0;
}
.mnt-5 {
  margin-top: -5px;
}
@media (min-width: 768px) {
  .inplace-edit-widget-wrap .appointment_main_wrapper {
    width: 100%;
  }
}
/* skeletin style: end */
@media (max-width: 1000px) {
  .appt-calender-wrapper .react-datepicker__day-name,
  .appt-calender-wrapper .react-datepicker__day,
  .appt-calender-wrapper .react-datepicker__time-name {
    margin: 0.3rem 0.4rem;
  }
}
@media (max-width: 500px) {
  .field_sep {
    width: 5px;
  }
  .rc-dialog-body .appt_wrapper {
    padding: 0;
  }
  .appointment_main_wrapper {
    width: 100%;
  }
  .appt-slot {
    width: 80px;
  }
}
.walking-appt-arrow {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.walking-appt-arrow:hover {
  background-color: var(--app-background-primary);
}
.walking-appt-arrow svg {
  font-size: 20px;
}
.walking-appt-arrow.left {
  left: -8px;
}
.walking-appt-arrow.right {
  right: -8px;
}
.patient-list-item.with-select-link{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px 10px 0;
}
.patient-list-item.with-select-link .patient-name{
  max-width: 80%;
}