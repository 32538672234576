.hospital-profile-container {
  display: flex;
  justify-content: space-around;
  padding-bottom: 30px;
}
@media (max-width: 810px) {
  .hospital-profile-container {
    flex-direction: column;
    padding-bottom: 15px;
  }
}
.hospital-section-left {
  width: calc(100% - 426px);
}
.hospital-section-right {
  width: 406px;
}
.hos-profile-img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.hos-profile-img .img-fluid {
  width: 100%;
  height: 100%;
  background-image: url("../../images/hospital-back.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-fluid .name-initials {
  font-size: 24px;
  font-weight: 700;
  color: white;
}
.doctor_list_wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0px -10px;
  max-height: 234px;
  overflow-y: auto;
}
.doctor_list_wrapper .spec_card{
  width: 49%;
}
.cursor_pointer{
  cursor: pointer;
}
.hos-name-flex-wrap .doc-fullname{
  max-width: 98%;
}
@media(max-width: 810px) {
  .hos-profile-image {
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}
.hospital_top_wrapper {
  display: flex;
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  align-items: flex-start;
}



@media (min-width: 1441px) {
  .hospital-profile-container {
    max-width: 1100px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0px auto 20px;
  }
}

@media (max-width: 1440px) {
  .hospital-profile-container {
    max-width: 85%;
    margin: 0px auto 20px;
  }
}

@media (max-width: 1300px) {
  .hospital-profile-container {
    max-width: 85%;
  }
}

@media (max-width: 1000px) {
  .hospital-section-left {
    width: calc(100% - 370px);
  }
  .hospital-section-right {
    width: 355px;
  }
  .hospital-section-left .font-size-18 {
    font-size: 16px !important;
  }

  .hospital-section-left {
    width: calc(100% - 400px);
  }
  .hospital-section-right {
    width: 380px;
  }
  .hospital_top_wrapper .doc-profile-detail{
    width: calc(100% - 120px);
    margin-left: 20px;
  }
  .hospital_top_wrapper .doc_appt_types{
    justify-content: flex-start;
  } 
}
.hospital_city {
  font-size: 16px;
  font-weight: 400;
  color: var(--text-color-dark);
  opacity: 0.5;
  position: relative;
}
.location_wrapper {
  display: flex;
  position: relative;
  justify-content: space-between;
}
.hospital-location-item {
  margin-bottom: 5px;
  text-align: start;
}
.hos_loc_left_section {
  width: auto;
}
@media (max-width: 810px) {
  .location_wrapper,
  .hospital_top_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.mr-5 {
  margin-right: 5px;
}

.hos-name-flex-wrap {
  justify-content: center;
}
@media(max-width: 1080px) and (min-width: 810px){
  .speciality_wrapper .spec_card_wrapper .spec_card{
    width: 100%;
  }
}
@media(max-width: 1000px) {
  .hos-name-flex-wrap {
    text-align: start;
  }
}
@media (max-width: 810px) {
  .hos-name-flex-wrap {
    text-align: center;
  }
  .hospital-section-left,
  .hospital-section-right {
    width: 100%;
  }
  .hospital-profile-container {
    flex-direction: column;
  }
  .hospital_top_wrapper {
    flex-direction: column;
  }
  .hos-profile-detail {
    margin-left: 0 !important;
    text-align: center;
    width: 100% !important;
  }
  .hospital_top_wrapper .doc_appt_types{
    justify-content: center;
  } 
}
.hospital_city .feature-hospital-icon.location::before {
  content: "";
  /* background-image: url("../../images/location.svg"); */
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  position: absolute;
  margin: 0 60px 0 0;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
}

.hospital-about-container {
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
}
.hospital-about-container p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 10px;
}
.hospital-awards-contributions {
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
}
.hospital-border {
  border-bottom: 1px solid #d1d6f3;
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: #d1d6f3;
}
.speciality_wrapper {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 20px;
  box-shadow: 0px 5px 25px #ebeef1;
  transition: all 0.3s ease-in;
}
.speciality_wrapper .accordion__button {
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
}
.speciality_wrapper .spec_card_wrapper {
  position: relative;
}
.speciality_wrapper .spec_card_wrapper .spec_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  margin: 15px -10px;
  padding: 5px 10px;
}
.speciality_wrapper .spec_head {
  margin: -15px 0 15px 0;
  padding: 0;
  font-weight: 700;
  font-size: 16px;
  color: #078dff;
  font-style: normal;
}
.speciality_wrapper .spec_card_wrapper .spec_card:hover {
  box-shadow: 0px 5px 25px #ebeef1;
  background-color: #fff;
}
.speciality_wrapper .spec_card_wrapper .spec_card .img-card {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.spec_card .img-card .img-card-inner{
  width: 100%;
  height: 100%;
  background: #d1d3f6 url("../../images/man.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-card-inner .name-initials{
  font-size: 12px;
  font-weight: 700;
  color: white;
}
.spec_card .content{
  width: calc(100% - 65px);
  line-height: 1.3;
}
.speciality_wrapper .spec_card_wrapper .spec_card h5 {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.speciality_wrapper .spec_card_wrapper .spec_card p {
  font-size: 14px;
  margin-bottom: 0;
}
.speciality_wrapper .spec_card_wrapper .spec_card .right-btn {
  position: absolute;
  right: 0;
  padding-right: 0px;
}
.speciality_wrapper .spec_card_wrapper .spec_card .right-btn button {
  width: 145px;
  padding: 5px 20px;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  background-color: #078dff;
  color: #fff;
}
.doctor_list_wrapper.spec_card_wrapper .spec_card{
  margin: 5px 0px;
}
@media (max-width: 810px) {
  .speciality_wrapper .spec_card_wrapper .spec_card .right-btn button {
    width: auto;
  }
}
.speciality_wrapper .speciality-search {
  display: flex;
  margin-bottom: 20px;
}
.speciality_wrapper .speciality-search input[type="search"] {
  width: 25vw;
  margin-right: 15px;
  height: 30px;
  padding: 5px 10px;
  border: 1px solid #d1d6f3;
  border-radius: 6px;
}
.speciality_wrapper .speciality-search select {
  width: 10vw;
  margin-right: 15px;
  height: 30px;
  padding: 5px 10px;
  border: 1px solid #d1d6f3;
  border-radius: 6px;
}
.hospital-service-container {
  background-color: #fff;
  padding: 10px 20px;
  margin: 0 0 0 0;
  border-radius: 6px;
}
@media (max-width: 810px) {
  .hospital-service-container {
    margin: 10px 0px;
  }
}
.services-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 4px;
  align-items: center;
}
.services-wrapper .services {
  width: auto;
  text-align: center;
  border: 1px solid #078dff;
  border-radius: 25px;
  margin: 0 4px 8px 0;
  font-size: 14px;
  line-height: 15px;
  padding: 4px 8px;
}
@media(max-width: 810px) {
  .services-wrapper .services {
    width: 49%;
    justify-content: space-between;
    align-items: center;
  }
}
@media(max-width: 600px) {
  .services-wrapper .services {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}
.hospital-insaurance-container {
  background-color: #fff;
  padding: 20px;
  margin: 10px 0 0 0;
  border-radius: 6px;
}
@media (max-width: 810px) {
  .hospital-insaurance-container {
    margin: 10px 0px;
  }
  .speciality_wrapper .spec_card_wrapper .spec_card{
    width: 100%;
  }
}
.insurance-card {
  display: flex;
  transition: all 0.3s ease-in;
  border: 1px solid transparent;
  
  position: relative;
}
.insurance-card .insurance {
  display: flex;
  padding: 10px 0;
  width: 100%;
}
.insurance-card .img-left {
  width: 20px;
  height: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  margin-top: 5px;
  overflow: hidden;
}
.img-left .ins-img{
  width: 100%;
  height: 100%;
  background-image: url("../../images/insurance.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.insurance-card:hover {
  border-top: 1px solid #d1d3f6;
  border-bottom: 1px solid #d1d3f6;
}
.insurance-card:hover .see-more {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
}
.insurance-card:hover .img-left {
  background-image: url("../../images/insurance-filled.svg");
}

.insurance-card h5 {
  margin: 0;
  padding: 0;
  font-size: 16px;
}
.insurance-card p {
  font-size: 14px;
  margin: 0;
  padding: 0;
}
.see-more {
  color: #078dff;
  font-size: 16px;
  font-weight: 600;
  font-size: 16px;
  display: none;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.hospital-heading h2 {
  font-size: 16px;
}
.awards_wrapper{
  list-style: none;
  padding: 0;
  margin: 0;
}
.awards_wrapper h3 {
  font-size: 14px;
  margin-bottom: 2px;
  margin-top: 0;
}
.awards_wrapper p {
  font-size: 14px;
  margin-bottom: 2px;
}

.specialities-accordion .accordion__panel {
  padding: 20px 0 0px 0;
}
.awards_wrapper li{
  border-bottom: 1px solid #d1d6f3;
  padding: 10px 0;
}
.awards_wrapper li:last-child{
  border-bottom: none;
  padding-bottom: 0;
}
.ins-content{
  width: calc(100% - 50px);
}
.readonly{
  pointer-events: none;
}