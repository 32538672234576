$text-color-dark: #353949;
$app-background: #f5f5f5;
$block-padding: 15px;
$primary-color: #078dff;
$primary-color-hover: #07c5ff;
$controls-radius: 3px;
$border-color: #e1e3ff;
$light-grey-color: #999ebc;
$btn-normal-height: 35px;
$btn-normal-line-height: 1em;
$btn-small-height: 28px;
$btn-small-line-height: 1em;
$color-red: #f63774;
$light-grey2-color: #d1d6f3;
$purple-color-hover: #6e75ff;
$purple-color: #545df9;
$green-color: #1fc9c1;
