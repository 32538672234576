#hospital_reviews {
  background-color: #fff;
  padding: 20px;
  margin: 10px 0 0 0; 
  border-radius: 6px; 
}
@media (max-width: 768px) {
  #hospital_reviews {
    margin: 10px 0px;
  }
}