:root {
  --app-background-primary: #f5f5f5;
  --card-shadow: 0 1px 2px rgba(189, 194, 220, 0.15);
  --bluey-grey: #999ebc;
  --text-color-dark: #353949;
  --light-grey-color: #d1d6f3;
  --cloudy-blue-28: rgba(189, 194, 220, 0.28);
  --btn-box-shadow: 0 2px 8px 0 var(--cloudy-blue-28);
  --normal-input-height: 35px;
  --controls-border-radius: 3px;
  --base-font: "Muli", sans-serif;
  --primary-gradient-bg: linear-gradient(101deg, #11c1ff, #078dff);
  
  --text-color-green-1: #53D769;
  --background-image-color: #9ED3F9;
  --sky-light-blue-color: #F5FAFF;
  --normal-select-height: 35px;
  --normal-select-line-height: 33px;
  --normal-select-line-height-multiple: 1.1;
  --text-type-modal-v-padding: 30px;
  --label-font-size: 14px;
  --default-btn-shadow: 0 5px 25px 0 rgba(189, 194, 220, 0.4);
  /* small btn  */
  --small-btn-height: 28px;
  --small-btn-line-height: 1.5;
  --small-btn-font-size: 14px;
  --small-icon-font-size: 16px;
  --small-select-height: 28px;
  --small-select-line-height: 26px;

  --text-color-red: #f63774;
  --base-font-size: 14px;
}
