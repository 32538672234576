
:root{
  --app-background-primary: #f5f5f5;
  --card-shadow: 0 1px 2px rgba(189, 194, 220, 0.15);
  --bluey-grey: #999ebc;
  --text-color-dark: #353949;
  --app-background-primary: #f5f5f5;
  --light-grey-color: #d1d6f3;
  --cloudy-blue-28: rgba(189, 194, 220, 0.28);
  --btn-box-shadow: 0 2px 8px 0 var(--cloudy-blue-28);
  --normal-input-height: 35px;
  --controls-border-radius: 3px;
  --base-font: "Muli", sans-serif;
  --primary-gradient-bg: linear-gradient(101deg, #11c1ff, #078dff);
  --text-color-primary: #078dff;
  --background-hover-color-primary: #07c5ff;
}
h1, h2, p{
  margin: 0;
  margin-bottom: 5px;
}
.doc-profile-wrapper {
  background: var(--app-background-primary);
  line-height: 1.5;
}
.doc-profile-info-container {
  background: #ffffff;
  box-shadow: var(--card-shadow);
  border-radius: 6px;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid var(--light-grey-color); */
  position: relative;
}
.doc-profile-info-left {
  display: flex;
  align-items: center;
  width: 56%;
}
.doc-profile-img {
  width: 85px;
  height: 85px;
  background-position: center top;
  background-size: cover;
  box-sizing: border-box;
  border-radius: 50%;
  background-repeat: no-repeat;
  position: relative;
  background-color:rgba(209, 214, 243, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #d1d6f3;
}
.doc-profile-img .name-initials{
  font-size: 24px;
  font-weight: 700;
  color: white;
}
.doc-profile-detail {
  width: calc(100% - 120px);
  margin-left: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.doc-profile-detail .doc-name {
  font-weight: bold;
  font-size: 25px;
  line-height: 44px;
  color: var(--text-color-dark);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 435px;
  display: inline-block;
}
.doc-fullname{
  font-weight: 900;
  font-size: 30px;
  line-height: 30px;
  color: var(--text-color-dark);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 435px;
  display: inline-block;
}
.doc-info-text{
  font-size: 16px;
  line-height: 16px;
  display: flex;
}
.doc_profile .doc-info-text{
  font-size: 14px;
}
.mt-5 {
  margin-top: 5px;
}
.light-grey-word-length {
  max-width: 200px;
}
.ml-10 {
  margin-left: 10px;
}
.color-light-grey {
  color: var(--bluey-grey);
}
.align-center {
  align-items: center;
}
.flex {
  display: flex;
}
.mt-12 {
  margin-top: 12px;
}
.appt-booking-amount {
  color: var(--text-color-dark);
}
.ml-15 {
  margin-left: 15px;
}
.doc-profile-main-sec {
  background: var(--app-background-primary);
  padding: 15px 0px 30px 0;
  margin: 0 auto;
}
.doc-profile-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 0px 0;
  margin: 0px auto 0px;
}
.doc-nav-btn-wrap {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.doc-nav-btn-wrap button + button {
  margin-left: 20px;
}
.doc-profile-info-right {
  /* display: flex;
  justify-content: flex-end;
  align-items: center; */
  max-width: 300px;
}
.profile_completion_text {
  color: var(--text-color-dark);
  font-size: 16px;
  font-weight: 700;
}
.profile_complete_subtext {
  color: var(--bluey-grey);
  font-size: 14px;
  font-weight: 500;
}
.doc-profile-info-right p {
  margin-bottom: 0;
}
.profile_completion_wrap {
  /* width: 38%;
  transform: translateX(130px); */
  text-align: center;
  transform: translateY(-10px);
}
.unchecked_tab {
  position: relative;
}
.unchecked_tab:after {
  content: "";
  position: absolute;
  right: -19px;
  top: 2px;
  width: 15px;
  height: 15px;
  background-color: var(--light-grey-color);
  border-radius: 50%;
}
.checked_tab {
  position: relative;
}
.checked_tab img {
  position: absolute;
  right: -16px;
  top: 1px;
  width: 10px;
  height: 16px;
  display: block;
  z-index: 1;
}
.checked_tab::after {
  content: "";
  position: absolute;
  right: -19px;
  top: 2px;
  width: 15px;
  height: 15px;
  background-color: var(--text-color-primary);
  border-radius: 15px;
}
.unchecked_tab img {
  display: none;
}
.tabs_wrapper {
  background-color: white;
  padding: 20px;
  border-radius: 6px;
  box-shadow: none;
  box-shadow: var(--card-shadow);
  /* border: 1px solid var(--light-grey-color); */
}
.line-tabs .ant-tabs-bar {
  border-bottom: 1px solid var(--light-grey-color);
}

.react-datepicker-wrapper .rct-datepicker {
  max-width: 95px;
}
.react-datepicker-wrapper .ant-btn {
  vertical-align: middle;
}
.react-datepicker-wrapper .ant-btn[readonly] {
  box-shadow: var(--btn-box-shadow) !important;
  background-color: #f5f5f5;
}
.react-datepicker-wrapper .ant-btn .anticon {
  left: 7.5px;
}
.react-datepicker-wrapper input {
  height: var(--normal-input-height);
  border: 1px solid var(--light-grey-color);
  border-radius: var(--controls-border-radius);
  font-family: var(--base-font);
  width: 100%;
  color: var(--text-color-dark);
  padding: 4px 11px;
  outline: none;
}
.form-heading {
  font-size: 25px;
  font-weight: 700;
  color: var(--text-color-dark);
  margin-bottom: 15px;
}
.form-wrapper {
  margin: 20px 0;
}
.custom_label {
  font-size: var(--label-font-size);
  font-weight: bold;
  color: var(--text-color-dark);
  margin-bottom: 3px;
}
.add-btn {
  font-size: 16px;
  display: inline-block;
  background-color: var(--text-color-primary);
  background-image: var(--primary-gradient-bg);
  color: white;
  border-radius: 6px;
  padding: 0px 18px;
  font-weight: 400;
  margin-left: 10px;
  transform: translateY(-3px);
}
.div-bt {
  border-top: 1px solid var(--super-light-grey-color);
}
/* .progressbar-wrapper {
  transform: translateX(60px);
} */
.spec-box {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10px;
  font-size: 16px;
  padding-bottom: 4px;
}
.lang-col {
  margin-left: 5px;
}
.certificate-title .ant-modal-title {
  font-size: 18px;
  font-weight: 600;
}
.certificate-img {
  max-width: 100%;
  height: 600px;
  overflow: scroll;
  margin-left: auto;
  margin-right: auto;
}
.ant-modal.kc-modal-large {
  width: 70% !important;
}
.ml-5 {
  margin-left: 5px;
}
.upload-wrapper {
  position: relative;
}
.upload-btn {
  position: absolute;
  width: 120px;
  height: 120px;
  top: -60px;
  left: -1px;
}
.doc-initials-block.large.rounded {
  width: 178px;
  height: 185px;
}
.upload-btn .ant-upload {
  height: 100%;
  width: 100%;
}
.upload-btn .ant-btn {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
}
.upload-btn .ant-btn:hover {
  opacity: 0.8;
  border-color: transparent;
  color: var(--text-color-primary);
  border-radius: 50%;
}
.upload-btn .ant-btn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.photo-thumbnail-round {
  width: 120px;
  height: 120px;
  background-position: center top;
  background-size: cover;
  border: none !important;
  box-sizing: border-box;
  overflow: hidden;
  background-repeat: no-repeat;
  position: absolute;
  top: -6px;
  left: -6px;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  border-radius: 50%;
  padding: 0;
}
.doc-profile-img:hover .photo-thumbnail-round {
  opacity: 1;
}
.tag_tran_top {
  transform: translateY(-2px);
}
.link-show-img:hover {
  text-decoration: underline;
}
.react-datepicker {
  border: 1px solid transparent;
  box-shadow: var(--box-shadow);
  border-radius: 0.5rem;
}
.react-datepicker__header {
  background-color: #f4f6fc;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  display: none;
}

.ant-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: var(--base-font-size);
}

.ant-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: var(--base-font-size);
}

.ant-input::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: var(--base-font-size);
}
.creatable-select-container .css-yk16xz-control {
  border-color: var(--light-grey-color);
  background-color: var(--app-background-primary);
}
.creatable-select-container .css-1okebmr-indicatorSeparator {
  background-color: var(--light-grey-color);
}
.creatable-select-container .css-tlfecz-indicatorContainer {
  color: var(--light-grey-color);
}
.creatable-select-container .css-1pahdxg-control,
.creatable-select-container .css-1pahdxg-control:hover {
  box-shadow: none;
  border-color: var(--purple-color);
}
.creatable-select-container .css-26l3qy-menu {
  transform: translateX(1px);
}
.creatable-select-container .css-yt9ioa-option,
.creatable-select-container .css-1n7v3ny-option {
  padding: 3px 12px;
}
.creatable-select-container .css-yk16xz-control {
  border-radius: 6px;
}
.edit-icon-btn {
  display: inline-block;
  width: 22px;
  height: 19px;
  background-image: url(../Appointments/images/pencil.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 15px auto;
}
.iframe_elem {
  overflow: hidden;
  height: 100vh;
  width: 100%;
}
.iframe_wrapper {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}
.verification-tag {
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 14px;
  position: absolute;
  left: -6px;
  top: -8px;
  z-index: 1;
  font-weight: 600;
  opacity: 0.9;
  transition: all 200ms;
  color: white;
}
.verification-tag:hover {
  opacity: 1;
}
.doc-name-flex-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.doc-name-flex-wrap .verification-tag {
  margin-left: 10px;
}
.action.edit-icon-btn {
  position: relative;
  top: 2px;
  left: 6px;
}
.upload-photo-icon {
  font-size: 25px !important;
  transform: translateY(5px);
}
.upload-photo-icon {
  width: 40px;
  height: 40px;
  /* background-image: url(../../images/appDoctorAssets/add_photo.svg); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.mb-20 {
  margin-bottom: 20px;
}
/* .mb-40 {
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .mb-40 {
    margin-bottom: 0;
  }
} */
.no-remove-btn .ant-upload-list-item-card-actions {
  display: none !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-50 {
  padding-bottom: 50px;
}
.verify-label {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  padding: 5px 8px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  border-radius: 4px;
  transition: all 200ms;
  opacity: 0.9;
}
.unverified-label {
  background-color: var(--text-color-red);
}
.verified-label {
  background-color: var(--text-color-green);
}
.verify-label:hover,
.verify-label:focus {
  color: white;
  opacity: 1;
}

.doc-search-form-container {
  position: relative;
  z-index: 1;
  padding-top: 150px;
  color: #ffffff;
}
.doc-search-top-heading {
  font-weight: 800;
  font-size: 58px;
  line-height: 80px;
  letter-spacing: 0.2px;
  color: #ffffff;
  margin-bottom: 10px;
}
.doc-search-top-text {
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
}
.form-input-wrapper .ant-input:not(.ant-select-search__field) {
  border-radius: 8px;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
}
.top-header-sign-in {
  font-size: 17px;
  color: #2b2c43;
  line-height: 17px;
  font-weight: 600;
}
.already-on-kulcare {
  font-size: 14px;
  font-weight: bold;
}
.already-on-kulcare a {
  color: #ffffff;
  text-decoration: underline;
}
.select-prof-container {
  position: relative;
}
.provide-list-heading {
  font-weight: bold;
  font-size: 32px;
  line-height: 24px;
  color: #353949;
}
.top-back-btn {
  position: absolute;
  top: -6px;
  left: 20px;
  width: 44px;
  height: 47px;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.back-icon {
  /* background-image: url("../../images/appDoctorAssets/back-arrow-icon.svg"); */
  width: 10px;
  height: 15px;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
}
.mt-40 {
  margin-top: 40px;
}
.doc-detail-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #353949;
}
.search-doc-name {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #353949;
}
.doc-detail-content {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
}
.title-grey-text {
  color: #999ebc;
  margin-right: 10px;
}
.list-last-icon {
  /* background-image: url("../../images/appDoctorAssets/blue-list-arrow-icon.png"); */
  width: 15px;
  height: 12px;
  background-size: contain;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  transform: translateX(-13px) translateY(1px);
}
.pb-30 {
  padding-bottom: 30px;
}
.wdt-20 {
  width: 20%;
}
.wdt-25 {
  width: 25%;
}
.wdt-30 {
  width: 30%;
}
.wdt-5 {
  width: 5%;
}
.mt-24 {
  margin-top: 24px;
}
.tr-1 {
  transform: translateY(1px) !important;
}
.home_more_btn {
  transform: translate(-8px, 10px);
}
.profile-tasks-block {
  background-color: var(--app-background-primary);
  border-radius: var(--controls-border-radius);
  padding: 20px;
}
.profile-strength-wrap {
  border-right: 1px solid var(--light-grey-color);
  margin-right: 20px;
}
.profile-strength-block {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-right: 20px;
}
.profile-strength-block div {
  background-color: #d8d8d8;
  height: 14px;
  width: 30%;
}
.ml-15 {
  margin-left: 15px;
}
.mb-10{
  margin-bottom: 10px;
}
h1::first-letter, 
.review-user::first-letter, 
.inplace-edit-widget-wrap p::first-letter{
  text-transform: capitalize;
}
.ant-skeleton-paragraph{
  list-style: none;
}
@media (min-width: 1441px) {
  .doc-profile-nav,
  .doc-profile-main-sec {
    max-width: 1192px;
  }
  .doc-profile-main-sec.in-container {
    max-width: 100%;
    padding: 20px;
  }
}
@media (max-width: 1440px) {
  .doc-profile-nav,
  .doc-profile-main-sec {
    max-width: 1192px;
  }
  .doc-profile-nav,
  .doc-profile-main-sec {
    max-width: 95%;
  }
  .doc-profile-main-sec.in-container {
    max-width: 100%;
    padding: 20px;
  }
}
@media (max-width: 1440px) {
  /* .doc-profile-main-sec, .doc-profile-nav {
    max-width: 1440px;
  } */
  .doc-info-text {
    display: block;
  }
  .spec-box {
    display: block;
    max-width: 100%;
  }
  .exp-col {
    margin-left: 0px;
  }
}
@media (max-width: 1350px) {
  .doc-profile-nav,
  .doc-profile-main-sec {
    max-width: 95%;
  }
  .doc-profile-main-sec.in-container {
    max-width: 100%;
    padding: 20px;
  }
}
@media (max-width: 1300px) {
  .doc-initials-block.large.rounded {
    width: 150px;
    height: 150px;
  }
  .doc-profile-detail .doc-name, .doc-fullname {
    font-size: 24px;
  }
  .doc-profile-detail {
    margin-left: 10px;
  }
  .doc-info-text {
    font-size: 14px;
    line-height: 20px;
  }
  .spec-box {
    margin-bottom: 5px;
  }
  .star-rate {
    width: 18px;
    display: inline-block;
  }
  .star-rate img {
    width: 100%;
  }
  .photo-thumbnail-round {
    width: 138px;
    height: 138px;
    top: 0;
    left: 0;
  }
  .verification-tag {
    transform: translateY(3px);
  }
  .upload-btn {
    top: -75px;
  }
}
@media (max-width: 1100px) {
  .doc-profile-info-left {
    width: 45%;
  }
  .profile_completion_wrap {
    text-align: center;
  }
  .photo-thumbnail-round {
    top: -6px;
    left: -6px;
  }
  .doc-initials-block.large.rounded {
    width: 100px;
    height: 100px;
  }
  .doc-profile-detail .doc-name, .doc-fullname {
    line-height: 32px;
    margin: 0;
    padding: 0;
  }
  .upload-btn {
    top: -50px;
  }
}
@media(max-width: 1000px){
  .doc-profile-img{
    margin-left: auto;
    margin-right: auto;
  }
  .doc-name-flex-wrap{
    justify-content: center;
  }
  .doc-profile-detail{
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 992px) {
  .form-heading {
    font-size: 20px;
  }
  .doc-profile-info-left {
    width: 49%;
  }
  .profile_completion_text {
    font-size: 14px;
  }
  .profile_completion_wrap {
    transform: translateX(0px);
  }
}
@media(max-width: 768px){
  .doc-name-flex-wrap{
    justify-content: center;
  }
}
@media (max-width: 650px) {
  .profile_complete_subtext {
    font-size: 13px;
  }
  .doc-profile-detail .doc-name, .doc-fullname {
    font-size: 20px;
  }
  .doc-initials-block.large.rounded {
    width: 100px;
    height: 100px;
  }
  .upload-photo-icon {
    font-size: 20px;
    transform: translateX(0px) translateY(3px);
  }
  .doc-profile-info-container {
    padding: 20px 15px;
  }
  .doc-info-text {
    font-size: 13px;
    line-height: 13px;
  }
  .appt-booking-amount {
    font-size: 13px;
  }
  .doc-profile-detail {
    width: calc(100% - 110px);
  }
  .doc-info-text {
    line-height: 15px;
  }
  .profile_completion_text {
    font-size: 13px;
  }
  .photo-thumbnail-round {
    width: 100px;
    height: 100px;
  }
  .doc-profile-info-container {
    flex-direction: column-reverse;
  }
  .doc-profile-info-left {
    width: 100%;
    align-items: flex-start;
  }
  .verification-tag {
    font-size: 12px;
  }
  .upload-btn {
    top: 0px;
  }
}
@media (max-width: 500px) {
  .tabs_wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
  .doc-profile-nav {
    width: 100%;
    padding: 15px 0px 0;
  }
  .doc-profile-main-sec {
    padding: 15px 0px 15px 0;
  }
  .doc-nav-btn-wrap button + button {
    margin-left: 0;
  }
  .doc-profile-nav .back-btn-link {
    margin-bottom: 10px;
  }
  .doc-nav-btn-wrap {
    width: 100%;
    justify-content: space-between;
  }
  .doc-initials-block.large.rounded {
    width: 70px;
  }
  .upload-btn {
    top: 0;
  }
  .doc-profile-detail .doc-name, .doc-fullname {
    width: 100%;
  }
  .doc-name-flex-wrap {
    margin-bottom: 10px;
  }
  .doc-name-flex-wrap .verification-tag {
    margin-left: 0;
  }
  .verify-doc-upload-container .ant-upload-list-item-name {
    max-width: 160px;
  }
  .appt-booking-amount {
    margin-left: 0;
  }
  .doc-profile-info-right {
    margin-bottom: 20px;
  }
}
