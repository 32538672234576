/***** custom fonts start*****/
@import url("https://fonts.googleapis.com/css?family=Muli:400,400i,600,700,900&display=swap");
:root{
  --text-color-green-1: #53D769;
}
/***** custom fonts end*****/
.font-weight-bold {
  font-weight: 700;
}
.font-weight-semibold {
  font-weight: 600;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-13 {
  font-size: 13px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-10 {
  font-size: 10px;
}
.font-size-20 {
  font-size: 20px;
}
.font-size-26 {
  font-size: 26px;
}
.font-color-primary {
  color: var(--text-color-dark);
}
.font-color-black {
  color: black;
}
.font-color-green {
  color: var(--text-color-green) !important;
}
.font-color-yellow {
  color: var(--text-color-yellow) !important;
}
.font-color-red {
  color: var(--text-color-red) !important;
}
.font-color-white {
  color: white;
}
.font-color-red {
  color: var(--text-color-red);
}
.font-color-white {
  color: white;
}
.font-color-grey {
  color: var(--bluey-grey);
}
.font-color-blue {
  color: var(--text-color-primary) !important;
}
.icon-color {
  color: var(--icons-color);
}
h1 {
  font-size: 25px;
}
.fz-22 {
  font-size: 22px;
}
.font-size-35 {
  font-size: 35px;
}
.font-weight-normal {
  font-weight: 400;
}
.font-size-18{
  font-size: 18px;
}
.font-color-green-1 {
  color: var(--text-color-green-1) !important;
}
.text-bold{
  font-weight: 700;
}
.font-size-24{
  font-size: 24px;
}
.font-color-light-grey {
  color: var(--bluey-grey);
}
.text-bolder{
  font-weight: 800;
}
.font-color-purple{
  color: #545df9;
}