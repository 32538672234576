@import "./variables.scss";
// grid
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center !important;
}
.vertical-center {
  vertical-align: middle;
}
.divider {
  border-bottom: 1px solid $border-color;
}
// utilities
.mt-5 {
  margin-top: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
// fonts
.font-wgt-normal {
  font-weight: normal;
}
.color-light-grey {
  color: $light-grey-color;
}
.color-dark {
  color: $text-color-dark;
}
.section-container {
  width: 90%;
  margin: auto;
}
.header-section {
  background: #fff;
  box-shadow: 0px 2px 8px rgba(189, 194, 220, 0.4);
  min-height: 60px;
  line-height: 60px;
  height: 60px;
  margin-bottom: 20px;

  .top-header-left-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .top-header-right-content{
    width: 120px;
    .filter-block{
      width: 100%;
      min-width: 100px;
    }
  }
  .logo-img {
    width: 90px;
    display: block;
    transform: translateY(3px);

    img {
      max-width: 100%;
    }
  }
}
