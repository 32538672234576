
.review-btns-wrapper {
  display: flex;
}
.review-btn-block {
  background-color: #f4f6fc;
  border-radius: 6px;
  padding: 8px 10px;
}
.review-btn-block + .review-btn-block {
  margin-left: 10px;
}
.rb-top {
  display: flex;
  align-items: center;
}
.rb-logo {
  width: 25px;
  height: 25px;
  display: inline-block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.rb-logo.kulcare {
  background-image: url("../Appointments/images/favicon.png");
}
.rb-logo.google {
  background-image: url("../Appointments/images/google.svg");
}
.reviews-wrapper {
  list-style: none;
  padding-left: 0;
}
.review-wrapper {
  border-bottom: 1px solid #d1d6f3;
  padding-bottom: 25px;
  padding-top: 20px;
}
.reviews-wrapper li.review-wrapper:last-child {
  padding-bottom: 0;
  border: none;
  padding-top: 10px;
}
.reviews-top-section {
  display: flex;
  align-items: center;
}
.rating-text {
  font-size: 20px;
  color: #353949;
  font-weight: 600;
  padding-left: 5px;
}
.review-on {
  display: flex;
  align-items: center;
  color: #999ebc;
  padding-left: 10px;
  font-weight: 700;
  font-size: 16px;
}
.review-on .rb-logo {
  width: 19px;
  height: 19px;
  margin-left: 7px;
}
.review-text {
  color: #353949;
  line-height: 20px;
  margin-top: 10px;
  font-size: 14px;
}
.review-by-block {
  display: flex;
  color: #999ebc;
  font-weight: 300;
  font-size: 14px;
  margin-top: 10px;
}
.review-date {
  margin-left: 20px;
}
:root {
  --star-size: 20px;
  --star-color: #EEEEEE;
  --star-background: #DE351F;
}

.stars {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: var(--star-size);
  font-family: Times;
  line-height: 1;
}
.stars::before {
  content: '★★★★★';
  letter-spacing: 3px;
  background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.rb-rating-count {
  font-size: 18px;
  font-weight: 600;
  color: #353949;
  padding-left: 5px;
}
.rb-count{
  font-size: 12px;
}