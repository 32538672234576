.teleconsultation-step-card {
  background: #ffffff;
  border: 1px solid #d1d6f3;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  padding: 10px 10px 10px 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
}
.teleconsultation-step-card.active {
  border: 2px solid #078dff;
}
.teleconsultation-step-card.complete {
  border: 1px solid #d1d6f3;
}
.teleconsultation-step-inner-heading {
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: #353949;
}
.inactive .teleconsultation-step-inner-heading {
  opacity: 0.7;
}
.teleconsultation-step-inner-sub-heading {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #999ebc;
}
.teleconsultation-card-right-caret {
  color: #353949;
  font-size: 13px;
  margin-left: 13px;
}
.teleconsultation-left-content {
  width: 84%;
  /* margin-right: 20px; */
}
.teleconsultation-right-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.teleconsultation-step-number {
  position: absolute;
  left: -16px;
  top: 60%;
  margin-top: -20px;
  background: #d1d6f3;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
.active .teleconsultation-step-number {
  background: #078dff;
  box-shadow: 0px 4px 10px rgba(153, 158, 188, 0.5);
  border-radius: 20px;
}
.complete .teleconsultation-step-number {
  background: #078dff;
}
.teleconsultation-right-content .amount {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}
.active .teleconsultation-right-content .amount {
  color: #078dff;
}
.active .teleconsultation-card-right-caret {
  color: #078dff;
}
.complete .teleconsultation-right-content .amount {
  display: none;
}
.complete .teleconsultation-card-right-caret {
  display: none;
}
.teleconsultation-tick {
  width: 15px;
}
.teleconsultation-prescription-img {
  width: 75px;
  height: 75px;
  background: url("../images/prescription-document.svg");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  border: 1px solid #d1d6f3;
  box-sizing: border-box;
  border-radius: 6px;
}
.teleconsultation-prescription-pdf-img {
  width: 75px;
  height: 75px;
  background: url("../images/pdf-icon.svg");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
}
.appointment-complete {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #1fc9c1;
  margin-top: 5px;
}
.appointment-missed {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #f63774;
  margin-top: 12px;
}
.complete .teleconsultation-step-number {
  text-indent: -1000px;
  /* background-image: url("../images/white-tick-img.svg"); */
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
}
.react-add-to-calendar {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  display: inline-block;
  margin-top: 12px;
}
.react-add-to-calendar__wrapper {
  zoom: 1;
  cursor: pointer;
}

.add-to-calendar-button {
  background: rgba(7, 141, 255, 0.1);
  border-radius: 6px;
  padding: 10px;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 11px;
  color: #078dff;
  display: inline-block;
  border: none;
}
.add-to-calendar-button span,
.add-to-calendar-button i {
  vertical-align: middle;
}
.add-to-calendar-button:hover,
.add-to-calendar-button:focus {
  background: rgba(7, 141, 255, 0.1);
}
.react-add-to-calendar__icon--right {
  padding-left: 5px;
}
/* .button-calender-icon {
  padding-right: 14px;
  background: url("../images/calender-icon.svg");
  width: 14px;
  height: 13px;
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  margin-right: 8px;
} */
.appt-calender-dropdown {
  position: absolute;
  box-shadow: 0px 8px 12px rgba(189, 194, 220, 0.2);
  z-index: 1;
  background: #ffffff;
  bottom: -230px;
  width: 173px;
}
.appt-calender-dropdown ul {
  padding: 13px;
  margin-bottom: 0;
}
.react-add-to-calendar__dropdown ul {
  list-style: none;
  margin: 0;
}
.appt-calender-dropdown ul li a {
  color: #353949;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.react-add-to-calendar__dropdown ul li a i {
  padding-right: 10px;
}
.appt-calender-dropdown ul li {
  margin-bottom: 15px;
}
.appt-calender-dropdown ul li:last-child {
  margin-bottom: 0;
}
.react-add-to-calendar .fa {
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

/* .react-add-to-calendar .fa-apple {
  background-image: url("../images/apple-icon.png");
}
.react-add-to-calendar .fa-google {
  background-image: url("../images/google-icon.png");
}
.react-add-to-calendar .outlook-link .fa-windows {
  background-image: url("../images/outlook-icon-orange.png");
}
.react-add-to-calendar .outlookcom-link .fa-windows {
  background-image: url("../images/outlook-icon.png");
}
.react-add-to-calendar .fa-google {
  background-image: url("https://image.similarpng.com/thumbnail/2020/12/Flat-design-Google-logo-design-Vector-PNG.png");
}
.react-add-to-calendar .fa-yahoo {
  background-image: url("../images/yahoo-icon.png");
}
.react-add-to-calendar .fa-office {
  background-image: url("../images/ms-office-icon.png");
} */
.patient-record-share-container {
  padding: 0 20px 30px 20px;
}
.patient-record-share-container .top-heading {
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: #353949;
}
.patient-record-share-container .ant-input {
  height: 48px !important;
}

.patient-record-share-container .ant-upload-text {
  display: none;
}
.patient-record-share-container .ant-upload {
  background: #078dff;
  border-radius: 6px;
  width: 93px;
  height: 93px;
  border: none;
  float: left;
}
.patient-records-upload
  .ant-upload.ant-upload-select-picture-card
  > .ant-upload {
  display: flex;
  justify-content: center;
  align-items: center;
}
.patient-record-share-container .anticon-plus {
  color: #ffffff;
  font-size: 22px;
}
.patient-record-share-container
  .ant-upload-list-picture-card
  .ant-upload-list-item {
  width: 93px;
  height: 93px;
  border: none;
  margin: 0 14px 20px 0;
  padding: 0;
}
.upload-file-wrapper {
  display: flex;
}
.upload-file-wrapper .images-limit-to-upload {
  margin-left: 18px;
  font-size: 14px;
  color: #353949;
  display: flex;
  align-items: center;
}
.patient-records-upload .ant-upload-list-picture-card {
  float: none;
}
.patient-records-upload {
  zoom: 1;
  display: inline-block;
  width: 100%;
}
.patient-records-upload
  .ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  border-radius: 6px;
}
.patient-record-share-container .ant-form-item {
  margin-bottom: 10px;
}
.share-record-gif {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.record-shared-sucess-msg {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #172b4d;
}
.record-shared-sucess-msg .bold {
  font-weight: bold;
}
.upload-photos-wrapper {
  position: relative;
}
.upload-photos-wrapper input {
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  cursor: pointer;
}
.upload-photos-wrapper label {
  width: 100%;
  min-height: 93px;
  line-height: 93px;
}
.upload-photos-wrapper label i.anticon {
  vertical-align: middle;
}
.upload_flex_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.upload-photos-wrapper {
  width: 93px;
  height: 93px;
  border-radius: 6px;
  margin-right: 8px;
  margin-top: 8px;
  text-align: center;
  cursor: pointer;
  background-color: var(--text-color-primary);
}
.pdf-icon {
  max-width: 80px;
  margin: 12px auto;
}
@media (max-width: 768px) {
  .appt_status_wrap {
    width: 80%;
  }
  .plr-10 {
    padding: 0 10px;
  }
}
.appointment-status-container {
  width: 375px;
  margin: auto;
}
.action-block {
  text-align: center;
  padding-left: 24px;
}
.action-block .phone {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/kulcare-assets/images/phone-blue.svg");
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-position: center;
  margin: 2px auto 7px;
}
.action-block .marker {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/kulcare-assets/images/marker-blue.svg");
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-position: center;
  margin: 10px auto 0;
}
.text-block {
  color: var(--text-color-primary);
  font-size: 9px;
}
.appointment-info-block {
  margin: 46px 0 60px;
  padding-left: 7px;
}
.kulcare-logo-wrap span {
  position: relative;
  top: 2px;
  left: -8px;
}
.green-text {
  color: var(--text-color-primary);
}
.orange-text {
  color: #fd986a;
}
.clinic-address {
  font-size: 16px;
}
.w-30 {
  width: 30px;
}
.w-27 {
  width: 27px;
}
.kulcare-logo-wrap {
  font-size: 13px;
}
.red-text {
  color: #d51800 !important;
}
.download-links-wrapper {
  padding-top: 36px;
}
@media (max-width: 400px) {
  .appointment-status-container {
    width: 300px;
  }
  .appointment-box .appt-status {
    font-size: 20px;
  }
  .appointment-box .appt-textline,
  .download-links-wrapper p {
    font-size: 13px;
  }
}

/* appointment.css start here */
.main-wrapper {
  max-width: 600px;
  margin: 0px auto 20px;
  padding: 0px 20px 15px;
  text-align: center;
  color: #353949;
  font-size: 14px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-18 {
  margin-top: 18px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-5 {
  margin-top: 5px;
}
.mr-8 {
  margin-right: 8px;
}
.verification-msg {
  line-height: 18px;
}
.verification-number {
  line-height: 18px;
}
.mt-20 {
  margin-top: 20px;
}
.input-field {
  background: #ffffff;
  border: 1px solid #d1d6f3;
  box-shadow: 0px 2px 8px rgba(189, 194, 220, 0.28) !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  line-height: 15px;
  color: var(--text-color-dark) !important;
  padding: 15px !important;
  height: 45px !important;
}
.resend-code-link {
  font-size: 12px;
  line-height: 15px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.full-btn {
  width: 100% !important;
}
.footer-heading {
  font-weight: bold;
}
.mt-22 {
  margin-top: 22px;
}
.mt-35 {
  margin-top: 35px;
}
li {
  list-style: none;
}
.footer-container {
  text-align: left;
}
.footer-list-container {
  padding-left: 0;
}
li .footer-list-instructions {
  line-height: 28px;
  padding-left: 10px;
}
.footer-list-container li {
  display: flex;
  align-items: center;
}
.download-link {
  text-align: center;
}
.payment-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  margin-top: 37px;
  text-align: left;
}
.app-detail {
  font-weight: bold;
  line-height: 18px;
  text-align: left;
}
img {
  max-width: 100%;
}
.payment-wrapper {
  display: flex;
}
.payment-doc-detail {
  padding-left: 25px;
  text-align: left;
}
.green-text {
  color: #1fc9c1 !important;
}
.violated-red-text {
  color: #f63774;
}
.blue-text {
  color: #078dff;
}
.ml-25 {
  margin-left: 25px;
}
.waiting-info {
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  margin-top: 13px;
}
.form-label {
  font-weight: bold !important;
  font-size: 13px !important;
  line-height: 16px !important;
  font-family: Muli !important;
}
.text-left {
  text-align: left;
}
.mt-115 {
  margin-top: 115px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-16 {
  margin-top: 16px;
}
.txt-bold {
  font-weight: bold;
}
.appointment-wrapper {
  border-top: 1px solid #d1d6f3;
  padding-top: 30px;
  text-align: left;
  line-height: 18px;
  text-transform: capitalize;
  margin-top: 30px;
}
.fmly-member {
  font-weight: bold;
}
.contact-no {
  color: #8d91ad;
}
.hide {
  display: none;
}
.show {
  display: block;
}

.virtul-component-wrapper {
  background: rgba(53, 57, 73, 0.9);
  color: white;
  margin: 0 -20px 0 -20px;
  padding: 20px 20px;
  text-align: left;
  padding-top: 15px;
}
.checkbox-heading {
  line-height: 18px;
  color: #ffffff;
}
.virtual-top-title {
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-left: 10px;
}
.doctor-img.virtual-doc-img {
  width: 95px;
  height: 95px;
}
.payment-doc-detail.virtual-doc-detail {
  padding-left: 15px;
}
.virtual-doc-detail span {
  font-size: 16px;
}
.virtual-doc-detai button {
  font-size: 14px;
  line-height: 18px;
}
.prescription-wrapper {
  padding: 30px 0;
  border-top: 1px solid #d1d6f3;
  text-align: left;
  line-height: 18px;
  margin-top: 30px;
}
.pdf-image {
  display: inline-block;
  background-image: url(../images/pdf-icon.svg);
  background-repeat: no-repeat;
  width: 28px;
  height: 36px;
  background-size: 28px 42px;
}
.pdf-link-container {
  padding: 5px;
  border: none;
  border-radius: 6px;
  display: inline-block;

  width: 75px;
  height: 75px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
}
.camera-switch-link {
  width: 50px;
  height: 50px;
  background-color: #999ebc;
  border-radius: 50%;
  background-image: url(../images/camera-switch.svg);
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: center;
  display: inline-block;
}

.end-call-link {
  width: 50px;
  height: 50px;
  background-color: var(--text-color-red);
  border-radius: 50%;
  background-image: url(../images/call-white.svg);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center;
  display: inline-block;
}
.video-mic-link {
  width: 50px;
  height: 50px;
  background-color: #999ebc;
  border-radius: 50%;
  background-image: url(../images/mic-on.svg);
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
  display: inline-block;
}
.video-mic-link.off {
  background-image: url(../images/mic-off.svg);
  background-color: white;
}
.video-camera-link {
  width: 50px;
  height: 50px;
  background-color: #999ebc;
  border-radius: 50%;
  background-image: url(../images/video-on.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
  display: inline-block;
}
.video-camera-link.off {
  background-image: url(../images/video-off.svg);
  background-color: white;
}
.pres-row {
  display: flex;
  align-items: center;
}
.call-duration {
  font-weight: 600;
}
.logo-container {
  position: relative;
  margin-top: 20px;
}
.logo-container a {
  display: inline-block;
  position: absolute;
  left: 0;
  color: #353949 !important;
}
.country-code {
  font-family: Muli;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
}
.hidden-button {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}
.short_link_wrapper {
  position: fixed;
  top: 56px;
  width: 100%;
  z-index: 1;
}

/* new video call style */
.allow-permission-content .heading {
  font-weight: 800;
  font-size: 24px;
  line-height: 22px;
  margin-bottom: 15px;
}
.allow-permission-content .text {
  font-size: 18px;
  line-height: 23px;
}
.mt-50 {
  margin-top: 50px;
}
.abs-video-call-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: calc(100vh - 70px);
  background: rgba(53, 57, 73, 0.9);
}
.video-call-top {
  position: absolute;
  width: 100%;
  height: 100px;
  display: flex;
  padding: 20px;
  justify-content: space-between;
}
.video-call-bottom {
  position: absolute;
  width: 100%;
  bottom: 10px;
  height: 70px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.patient-stream {
  width: 77px;
  height: 96px;
  border-radius: 10px;
  border: 1px solid white;
}
.doctor-stream {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.call-end-popover {
  max-width: 300px;
}
.call-end-popover .heading {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: var(--text-color-dark);
  margin-bottom: 10px;
}
.call-end-popover .text {
  font-size: 14px;
  line-height: 18px;
  color: var(--text-color-dark);
}
.video-call-middle {
  position: absolute;
  color: white;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}
.timer-wrapper {
  max-width: 302px;
  margin: auto;
}
.timer-wrapper .heading {
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 15px;
}
.timer-wrapper .text {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-top: 15px;
}
.timer-wrapper .countdown-timer {
  font-size: 80px;
  font-family: "ticklingtimebomb", sans-serif;
}
.payment_heading {
  font-weight: 700;
  font-size: 20px;
  color: #353949;
}
.payment_box label {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--bluey-grey);
}
.heading_info {
  font-size: 26px;
  font-weight: 600;
  color: var(--text-color-dark);
}
.payment_box {
  margin-bottom: 40px;
}
.heading_info_sm {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-color-dark);
}
.flex_centered_wrap {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.flex_centered_wrap label {
  margin-right: 10px;
  min-width: 60px;
}
.fz_18 {
  font-size: 18px;
}
.expired-content-container {
  max-width: 992px;
  margin: 5% auto;
  min-height: 80vh;
}
.expired-link-wrapper {
  padding: 0 20px;
}
.mtb-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.expiry-link-title {
  font-size: 26px;
  color: var(--text-color-dark);
  font-weight: 700;
  -webkit-font-smoothening: antialiased;
}
.expiry-link-subtitle {
  font-size: 18px;
  font-weight: 500;
  -webkit-font-smoothening: antialiased;
  color: var(--text-color-dark);
}
.centered-text {
  text-align: center;
}
.payment_detail_wrapper {
  margin-top: 40px;
}
.payment-card {
  padding: 20px;
}
.OT_publisher {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
}
.OT_subscriber {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
}
.teleconsult-view-wrapper {
  min-height: 50vh;
}
/* patient verify css */
.video-call-container {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-color: white;
  min-height: calc(100vh - 70px);
  margin-top: 10px;
}
.verification-top-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.verification-heading-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #353949;
  text-align: center;
}
.verification-sub-text {
  font-weight: bold;
  font-size: var(--label-font-size);
  color: var(--text-color-dark);
  text-align: left;
}
.verification-page-content {
  padding: 0px 0px 20px 5px;
}
.verify-otp-box {
  background: #ffffff;
  box-shadow: 0px 7.26437px 14.5287px rgba(85, 86, 138, 0.06);
  border-radius: 8px;
  width: 35px !important;
  height: 35px !important;
  line-height: 35px;
  font-weight: 600;
  color: #353949;
  border: 1px solid var(--light-grey-color);
  margin-right: 0px !important;
  outline: none;
}
.verify-otp-box:focus {
  border: 1px solid #078dff;
}
.verify-mobile-number {
  color: #353949;
}
.verify-send-again-link {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #078dff;
}
.video-appt-outer-container {
  padding: 0px 38px 0px 20px;
}
.patient-appointment-card {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(153, 158, 188, 0.5);
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  position: relative;
  width: 520px;
  margin-left: auto;
  margin-right: auto;
}
.appointment-card-left-content {
  width: 123px;
  padding: 15px 0px 15px 0px;
  background: #078dff;
  color: #ffffff;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.appointment-card-left-content > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.appointment-card-right-content {
  width: calc(100% - 123px);
  padding: 15px 16px 15px 25px;
}
.appointment-card-right-content .title {
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 2px;
  color: #999ebc;
}
.doc-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #353949;
}
.appt-datetime-bigger-fnt {
  font-weight: bold;
  font-size: 24px;
}
.appt-mnth-small-fn {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 2px;
  /* padding-left: 5px; */
}
.next-step-title {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 2px;
  color: #999ebc;
  text-transform: uppercase;
}
.dark-modal {
  background: rgba(53, 57, 73, 0.9);
}
.dark-modal .ant-modal-content,
.dark-modal .ant-modal-body {
  background: transparent;
  box-shadow: none;
  color: white;
}
.dark-modal .ant-modal {
  top: 50px;
}
.lh-18 {
  line-height: 18px;
}
.pl-4 {
  padding-left: 4px;
}
.appointment-card-right-content {
  display: flex;
  align-items: center;
}
.mt-80 {
  margin-top: 80px;
}
.try-3 {
  transform: translateY(3px);
}
.phone_number_wrapper {
  margin-top: 5px;
}
.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
}
.dot-flashing {
  position: relative;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: var(--text-color-dark);
  color: var(--text-color-dark);
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  top: 4px;
}
.dot-flashing::before {
  left: -7px;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: var(--text-color-dark);
  color: var(--text-color-dark);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::after {
  left: 8px;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: var(--text-color-dark);
  color: var(--text-color-dark);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.phone-pos .ant-collapse-extra {
  left: -17px !important;
}
.payment-pos .ant-collapse-extra {
  left: -19px !important;
  top: 9px !important;
}
.appt-pos .ant-collapse-extra {
  top: 7px !important;
  left: -18px !important;
}
@keyframes dotFlashing {
  0% {
    background-color: var(--text-color-dark);
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
@media (max-width: 680px) {
  .patient-appointment-card {
    max-width: 520px;
    width: 100%;
  }
  .appointment-card-left-content {
    width: 100px;
  }
  .appointment-card-right-content {
    width: calc(100% - 100px);
  }
  .verification-page-content {
    padding: 0px 20px 0 20px;
  }
  .teleconsultation-step-inner-heading {
    line-height: 17px;
    font-size: 14px;
  }
  .pdf-link-container {
    width: 25px;
    height: 50px;
  }
  .video-appt-outer-container {
    padding-left: 0;
    padding-right: 0;
  }
  .verification-page-content {
    padding: 0px 10px 0 10px;
  }
}
@media (max-width: 500px) {
  .expiry-link-title {
    font-size: 18px;
  }
  .expiry-link-subtitle {
    font-size: 13px;
  }
  .expiry-link-image-box-new {
    height: 300px;
    width: 100%;
  }
  .expired-content-container {
    margin: 40% auto;
    min-height: auto;
  }
  .payment_main_wrap .ant-card-bordered {
    border: none;
  }
  .payment_main_wrap .auth-form-row {
    top: 0;
    margin: 0;
    height: 100vh;
    background-color: white;
  }

  .payment-success-image-box {
    height: 200px;
    width: 100%;
  }
}
/* appointment.css ends here */

/* form collapse */
.ant-collapse.form-collapse {
  border: none;
  background-color: transparent;
}
.ant-collapse.form-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 5px;
  padding-left: 16px;
  font-size: var(--base-font-size);
  color: var(--text-color-primary);
  font-weight: 700;
  /* width: 30px; */
}
.ant-collapse.form-collapse > .ant-collapse-item {
  border: none;
  border-radius: 0;
  padding-left: 10px;
  /* display: flex; */
}
.form-collapse.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-extra {
  float: left;
  color: var(--text-color-dark);
  position: absolute;
  left: -20px;
  z-index: 1;
  top: 6px;
}
.form-collapse .ant-collapse-content {
  overflow: visible;
}
.form-collapse .ant-collapse-item-active {
  box-shadow: none;
}
.form-collapse .ant-collapse-content {
  border-top: none;
  /* width: calc(100% - 30px); */
  position: relative;
  margin-bottom: 10px;
}
.form-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding-bottom: 24px;
  padding-right: 0;
  padding-top: 10px;
}
.form-collapse .ant-collapse-content:before {
  content: "";
  width: 2px;
  background-color: var(--light-grey-color);
  height: calc(100% + 20px);
  position: absolute;
  top: -28px;
  left: 5px;
}
.form-collapse .ant-collapse-content:after {
  content: "";
  left: 10px;
  position: absolute;
  bottom: 0;
  height: 1px;
  width: calc(100% - 10px);
  background-color: var(--light-grey-color);
}
.form-collapse .ant-collapse-item:last-child .ant-collapse-content {
  margin-bottom: 0;
}
.form-collapse .ant-collapse-item:last-child .ant-collapse-content:after {
  display: none;
}
.underlined_text {
  text-decoration: underline;
}
