.doctor-profile-container {
  display: flex;
  justify-content: space-between;
}
.profile-section-left {
  width: calc(100% - 410px);
}
.profile-section-right {
  width: 395px;
}
.inplace-edit-widget-wrap {
  width: 100%;
  background: #ffffff;
  box-shadow: var(--card-shadow);
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 20px;
  position: relative;
}
.inplace-edit-widget-wrap li {
  position: relative;
}
.inplace-edit-widget-wrap .list-actions {
  position: absolute;
  right: 0;
  top: 2px;
  transition: all 0.2s 0s;
  display: none;
}
.inplace-edit-widget-wrap li:hover .list-actions {
  display: block;
}
.like-popup {
  z-index: 100;
}

.widget-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.list_with_btm_border {
  list-style: none;
  padding: 0;
  margin: 0;
}
.list_with_btm_border li {
  border-bottom: 1px solid var(--light-grey-color);
  margin-top: 10px;
  padding-bottom: 10px;
  line-height: 1.4;
}
.list_with_btm_border li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.d-flex {
  display: flex;
}
.flex-valign-centered {
  align-items: center;
}
.edu-icon-box {
  width: 30px;
  transform: translateY(4px);
}
.edu-icon-box img {
  max-width: 100%;
}
.flexbox-with-icons {
  display: flex;
  align-items: center;
}
.profile_top_wrapper {
  display: flex;
}

.widget-backdrop {
  width: 100%;
  height: 100%;
  left: 0;
  top: 48px;
  background-color: rgba(53, 57, 73, 0.3);
  position: fixed;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s 0s;
}
.widget-backdrop.show {
  opacity: 1;
  z-index: 99;
}
.flex-heading-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.absolute-options {
  position: absolute;
  right: 20px;
  z-index: 100;
}
.customPanelStyle-nobg {
  background: transparent;
  margin-bottom: 10px;
  border-radius: 2px;
  border-bottom: 1px solid;
}
.ant-collapse-borderless > .customPanelStyle-nobg.ant-collapse-item {
  box-shadow: none;
  border: none;
}
.ant-collapse
  > .customPanelStyle-nobg.ant-collapse-item
  > .ant-collapse-header {
  font-weight: 400;
  border-bottom: 1px solid var(--light-grey-color);
  font-size: 18px;
}
.ant-collapse
  > .customPanelStyle-nobg.ant-collapse-item-active
  > .ant-collapse-header {
  font-weight: 700;
}
.ant-collapse .customPanelStyle-nobg:last-child {
  border-bottom: none;
}
.customPanelStyle-nobg .ant-collapse-header {
  padding-left: 0 !important;
  padding-right: 40px !important;
}
.accordion-control.right-align {
  right: 13px;
  left: auto !important;
}
.accordion-control.right-align .minus-icon {
  left: auto;
  right: -6px;
}
.customPanelStyle-nobg .ant-collapse-content-box {
  padding: 10px 16px 16px 0px !important;
}
.location-item {
  padding-left: 25px;
  margin-bottom: 5px;
  position: relative;
  /* text-align: start; */
  font-size: 14px;
  line-height: 21px;
}
.location-item svg {
  position: absolute;
  left: 0;
}
.location-item.tp-2 svg {
  top: 2px;
}
.location-item.tp-3 svg {
  top: 3px;
}
.location-item.tp-5 svg {
  top: 5px;
}
.doc_appt_types{
  display: flex;
}

.appt-calender-wrapper .react-datepicker__day-name, .appt-calender-wrapper .react-datepicker__day, .appt-calender-wrapper .react-datepicker__time-name{
  margin: 0.3rem 0.5rem;
}
.trx-2{
  transform: translateX(2px);
}

@media (min-width: 1441px) {
  .doctor-profile-container {
    max-width: 1100px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 20px auto;
  }
}

@media (max-width: 1440px) {
  .doctor-profile-container {
    max-width: 85%;
    margin: 20px auto;
  }
}

@media(max-width: 1100px){
  .doc_appt_types{
    flex-wrap: wrap;
  }
}

@media (max-width: 1000px) {
  .profile-section-left {
    width: calc(100% - 370px);
  }
  .profile-section-right {
    width: 355px;
  }
  .profile-section-left .font-size-18 {
    font-size: 16px !important;
  }

  .profile-section-left {
    width: calc(100% - 400px);
  }
  .profile-section-right {
    width: 380px;
  }
  .appt-calender-wrapper .react-datepicker__month-container {
    padding: 10px !important;
  }
  .rc-dialog-body .appt-calender-wrapper .react-datepicker__month-container{
    padding: 0;
  }
  .appt-calender-wrapper .react-datepicker__current-month,
  .appt-calender-wrapper .react-datepicker-time__header,
  .appt-calender-wrapper .react-datepicker-year-header{
    font-size: 12px;
  }
  .appt-calender-wrapper .react-datepicker__day-name{
    font-size: 10px;
  }
  .appt-calender-wrapper .react-datepicker__day{
    font-size: 12px;
  }
  .appt-calender-wrapper .react-datepicker__day-name, .appt-calender-wrapper .react-datepicker__day, .appt-calender-wrapper .react-datepicker__time-name{
    width: 25px;
  }
  .doc_appt_types{
    justify-content: center; 
  }
  .react-datepicker__header{
    margin-left: -4px;
  }
  .react-datepicker__month{
    margin: 0;
    margin-left: -4px;
  }
}

/* widget transitions  start */
.widget-transition-enter {
  opacity: 0;
  display: none;
  transform: scale(0.9);
}
.widget-transition-enter-active {
  opacity: 1;
  display: block;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.widget-transition-exit {
  opacity: 1;
}
.widget-transition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.widget-transition-exit-done {
  display: none;
}
.accordion__button {
  background-color: white;
  font-weight: 400;
  position: relative;
  padding: 8px 10px;
  padding-left: 0;
  border-bottom: 1px solid var(--light-grey-color);
  font-size: 14px;
  color: var(--text-color-dark);
}
.accordion__button:hover {
  background-color: white;
}
.accordion__button:first-letter {
  text-transform:uppercase;
}
.accordion {
  border: none;
}
.accordion__item + .accordion__item,
.accordion__panel {
  border-top: none;
}
.accordion__button:before {
  content: "";
  /* font-size: 27px; */
  position: absolute;
  right: 0;
  border-bottom: none;
  border-right: none;
  transform: none;
  bottom: 7px;
  color: #999EBC;
  width: 15px;
  height: 15px;
  background-image: url(../../images/hiPlus.svg);
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 0;
}
.accordion__button[aria-expanded="true"]{
  font-weight: 700;
}
.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  content: "";
  transform: none;
  /* font-size: 38px; */
  bottom: 7px;
  background-image: url(../../images/hiMinus.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
.accordion__panel {
  padding-left: 0;
}
.doctor_spec_brief_list{
  display: flex;
  padding-left: 0px;
  margin-bottom: 2px;
  margin-top: 0;
}
.doctor_spec_brief_list li{
  padding-right: 22px;
}
.doctor_spec_brief_list li:first-child{
  list-style: none;
}
.doc-spec-box{
  font-size: 14px;
  font-weight: 600;
}
.doctor_city{
  font-size: 12px;
  font-weight: 400;
  color: var(--text-color-dark);
  opacity: 0.5;
}
.location_wraper{
  display: flex;
  position: relative;
  justify-content: space-between;
}
.loc_left_section, .loc_right_section{
  width: 48%;
}
.blue-link{
  color: var(--text-color-primary);
}
.doc_appt_box{
  padding: 3px 10px 3px 5px;
  border-radius: 104px;
  color: var(--text-color-dark);
  margin-right: 10px;
  font-size: 11.8px;
  display: flex;
  align-items: center;
  border: 0.5px solid #d1d6f3;
  background-color: rgba(209, 214, 243, 0.2);
  margin-bottom: 5px;
}
.doc_appt_box.inperson_type{
  /* border-color: var(--background-hover-color-primary); */
}
.doc_appt_box.video_visits{
  /* border-color: var(--text-color-primary); */
}
.doc_appt_box.appts{
  /* border-color: var(--purple-color); */
}
.doc_appt_icon{
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.doc_appt_box.inperson_type .doc_appt_icon{
  background-color: var(--background-hover-color-primary);
}
.doc_appt_box.video_visits .doc_appt_icon{
  background-color: var(--text-color-primary);
}
.doc_appt_box.appts .doc_appt_icon{
  background-color: var(--purple-color);
}
.mb-15{
  margin-bottom: 15px;
}
.mr-5{
  margin-right: 5px;
}
.accordion__panel{
  padding: 5px;
  padding-left: 0;
}
@media(max-width: 1100px){
  .doctor_spec_brief_list{
    list-style:  none;
  }
  .doc-spec-box{
    font-size: 16px;
  }
  .doc_appt_box{
    font-size: 12px;
    margin-bottom: 5px;
  }
}
@media(max-width: 1000px){
  .doctor_spec_brief_list li{
    padding-right: 0;
  }
  .location_wraper, .profile_top_wrapper{
    flex-direction: column;
  }
  .loc_left_section, .loc_right_section{
    width: 100%;
  }
}
@media(max-width: 810px){
  .doctor_spec_brief_list{
    flex-direction: column;
    text-align: center;
  }
  .doctor_city{
    text-align: center;
  }
}
@media (max-width: 768px) {
  .profile-section-left,
  .profile-section-right {
    width: 100%;
  }
  .doctor-profile-container{
    flex-direction: column;
  }
  .profile_top_wrapper{
    flex-direction: column;
  }
  .doc-profile-detail{
    margin-left: 0 !important;
    text-align: center;
    width: 100% !important;
  }
  .fees_block {
    justify-content: center;
  }
}
