@import "./variables.scss";
.radio-container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:last-child {
    margin-bottom: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: $light-grey-color;
    border-radius: 50%;
    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 5px;
      left: 5px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked {
      ~ {
        .checkmark {
          background-color: $primary-color;
          &:after {
            display: block;
          }
        }
      }
    }
  }
}
