.btn {
  border: none;
  padding: 5px 25px;
  background-color: white;
  height: 35px;
  line-height: 1em;
  color: #353949;
  border-radius: 3px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s 0s;
  font-size: 14px;
}
.btn:hover {
  background-color: #e1e3ff;
}
.primary {
  background-color: #078dff;
  color: white;
}
.primary:hover {
  background-color: #07c5ff;
}
.small {
  height: 28px;
  line-height: 1em;
  padding: 5px 20px;
}
.btn.disabled{
  pointer-events: none;
  opacity: 0.6;
}
.ghost {
  background-color: white !important;
  color: #078dff !important;
  border: 1px solid #078dff !important;
  text-align: center;
  padding: 5px 0px !important;
}
.ghost:hover{
  color: white !important;
  background-color: #078dff !important;
}
