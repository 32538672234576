@import "./variables.scss";
.search-icon {
  cursor: pointer;
  .icon {
    display: none;
    @media (max-width: 667px) {
      display: block;
      position: absolute;
      right: 25px;
      top: 20px;
      font-size: 25px;
    }
  }
}
.search-bar-wrap {
  background: #ffffff;
  border: 1px solid #d1d6f3;
  border-radius: 43px;
  margin-left: 50px;
  height: 35px;
  width: 50%;
  display: flex;
  line-height: 2em;
  &.header {
    @media (max-width: 667px) {
      display: none;
    }
  }
  @media (max-width: 667px) {
    width: 100%;
    margin-left: 10px;
    height: 30px;
  }
  & > input {
    width: 74%;
    position: relative;
    left: 5px;
    padding: 0px 10px;
    border: none;
    margin: 0px;
    align-self: center;
    text-align: start;
    font-size: 14px;
    @media (max-width: 667px) {
      width: 100%;
      padding: 0 5px;
      border: 1px solid #d1d6f3;
      margin: 0px 0 10px -5px;
      padding: 8px 0 8px 9px;
      text-align: start;
      border-radius: 3px;
    }

    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: none;
    }
  }
  .search-type-icon {
    align-self: center;
    padding-left: 10px;
    border-right: 1px solid $light-grey2-color;
    @media (max-width: 667px) {
      padding-left: 8px;
      border-right: none;
    }
    &.hospitals {
      .select-search__value::before {
        content: "";
        background-image: url("../images/hospital-icon.svg");
        background-size: cover;
        background-repeat: no-repeat;
        width: 16px;
        height: 15px;
        position: absolute;
        top: 5px;
        padding-right: 5px;
        z-index: 111;
      }
    }
    &.doctors {
      @media (max-width: 667px) {
        display: block;
        border: 1px solid #d1d6f3;
        padding-left: 8px;
        padding-top: 3px;
        padding-bottom: 3px;
        margin-bottom: 10px;
        border-radius: 4px;
      }
      .select-search__value::before {
        content: "";
        background-image: url("../images/person.svg");
        background-size: cover;
        background-repeat: no-repeat;
        width: 14px;
        height: 15px;
        position: absolute;
        top: 5px;
        padding-right: 5px;
        z-index: 111;
      }
    }
    .select-search {
      @media (max-width: 1260px) {
        width: 96%;
      }
    }

    .select-search__input {
      height: 25px;
      border: none;
      padding-left: 20px;
      padding-right: 0px;
      line-height: 25px;
    }
    .select-search__value {
      &::after {
        display: none;
      }
      // &::before {
      //   content: "";
      //   background-image: url("../images/hospital-icon.svg");
      //   background-size: cover;
      //   background-repeat: no-repeat;
      //   width: 16px;
      //   height: 15px;
      //   position: absolute;
      //   top: 5px;
      //   padding-right: 5px;
      // }
    }
  }
  .location-search {
    align-self: center;
    padding-left: 0px;
    @media (max-width: 667px) {
      border: 1px solid $light-grey2-color;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-left: 8px;
      border-radius: 3px;
    }
    .select-search {
      width: 95%;
      border-left: 1px solid $light-grey2-color;
      padding-left: 25px;
      @media (max-width: 1260px) {
        width: 90%;
      }
      @media (max-width: 667px) {
        border-left: 0;
        padding-left: 0px;
      }
    }

    .select-search__input {
      height: 25px;
      border: none;
      padding-left: 20px;
      padding-right: 0px;
      line-height: 25px;
    }
    .select-search__value {
      &::after {
        display: none;
      }
      &::before {
        content: "";
        background-image: url("../images/location-icon.svg");
        background-size: cover;
        width: 13px;
        height: 16px;
        position: absolute;
        top: 5px;
      }
    }
  }
}

.search-bar-modal {
  .rc-dialog-content {
    height: 260px;
  }
  .search-bar-wrap {
    width: 96%;
    @media (max-width: 667px) {
      display: block;
      border: 0;
      width: 100%;
      margin: 0;
    }
  }
}

.filter-bar-modal {
  .rc-dialog-content {
    height: 225px;
  }
  .filters-content {
    width: 96%;
    @media (max-width: 667px) {
      display: block;
      border: 0;
      width: 100%;
    }
  }
}

.doc-search-wrapper {
  background: $app-background;
  min-height: 100vh;
}
.search-nav {
  display: flex;
  padding: $block-padding;
  align-items: center;
  .search-left {
    width: calc(100% - 50px);
    font-size: 14px;
    display: flex;
    color: $light-grey-color;
  }
  @media (max-width: 667px) {
    .search-left {
      width: 100%;
      justify-content: space-between;
      padding: auto;
      margin: 0;
    }
  }
  .location {
    width: 20%;
  }
  .timings {
    width: 20%;
  }
  .hospital-booking-link {
    width: 20%;
  }
  .type {
    width: 20%;

    min-height: 30px;
    margin-left: 5px;
  }
}
@media (max-width: 667px) {
  .search-nav {
    flex-direction: row;
  }
}

.doc-search-main-section {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  height: calc(100vh - 62px);
}
@media (max-width: 768px) {
  .doc-search-main-section {
    flex-direction: column;
  }
}
.doc-search-left-content {
  width: 64%;
  background: #ffffff;
  box-shadow: 0px 5px 25px #ebeef1;
  border-radius: 6px;
  @media (max-width: 992px) {
    width: 100%;
  }
}
.doc-listing-wrap {
  height: calc(100vh - 157px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}
.doc-apt-links-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateX(-7px);
}
.location-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.result-found {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: $light-grey-color;
  padding: 0px 15px;
  margin-top: -10px;
  margin-bottom: 5px;
}
.busy-block {
  display: flex;
  span {
    font-size: 12px;
    padding: 3px 8px;
    border: 1px solid $border-color;
    color: $light-grey-color;
    &:first-child {
      border-right: none;
    }
    &:last-child {
      border-left: none;
    }
  }
}
.doc-search-right-content {
  @media (max-width: 992px) {
    display: none;
  }
  width: calc(36% - 10px);
  & > div {
    & > div {
      border-radius: 6px;
    }
  }
}
.search-map {
  width: 100%;
  height: calc(100vh - 80px);
}
.doc-search-list-wrapper {
  padding: 16px;
  background: #ffffff;
  border-bottom: 1px solid #e1e3ff;
  position: relative;
  @media (max-width: 667px) {
    display: block;
    justify-content: center;
    align-items: center;
  }
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 4px;
    background-color: $primary-color;
    left: 0;
    top: 0;
    transition: all 0.3s 0s;
    opacity: 0;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
  }
  .doc-detail {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    max-width: calc(100% - 120px);
    cursor: pointer;
  }
  .doc-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
  }
  .doc-speciality {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    margin-left: 5px;
  }
  .search-doc-info-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
  }

  // new styles
  .hospital-image {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    background-size: cover;
    background-image: url("../images/person-icon.svg");
    align-self: flex-start;
    @media (max-width: 667px) {
      margin: auto;
    }
  }

  .search-image {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #d1d6f3;
    background-size: cover;
    background-repeat: no-repeat;
    img {
      width: 100%;
    }
    .name-initials {
      font-weight: 700;
      font-size: 16px;
      color: white;
    }
  }
  @media (max-width: 667px) {
    .search-image {
      margin: auto;
    }
  }
  .search-info {
    width: calc(100% - 252px);
    padding-left: 16px;
    padding-right: 16px;
    align-self: flex-start;
    @media (max-width: 667px) {
      width: 100%;
      text-align: center;
    }
    .name {
      color: $text-color-dark;
      font-size: 18px;
      font-weight: 900;
      cursor: pointer;
    }

    .location {
      color: #707070;
      opacity: 0.9;
      font-size: 14px;
      line-height: 18px;
      margin-top: 7px;
      display: flex;
      margin-top: 5px;
      @media (max-width: 667px) {
        justify-content: center;
        align-items: center;
      }
      .location-text {
        max-width: calc(100% - 62px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
        @media (max-width: 667px) {
          display: block;
        }
      }
      .location-icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 5px;
        background-image: url("../images/location.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .timings {
      color: #707070;
      opacity: 0.9;
      font-size: 14px;
      line-height: 18px;
      margin-top: 7px;
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 667px) {
        justify-content: center;
      }

      margin-top: 5px;
      .timing-text {
        max-width: calc(100% - 62px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
      }
      .black-color {
        color: black;
      }
      .clock-icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 5px;
        background-image: url("../images/clock.svg");
        background-repeat: no-repeat;
      }
    }
    .hospital-booking-link {
      color: #078dff;
      opacity: 0.9;
      font-size: 14px;
      line-height: 18px;
      margin-top: 5px;
      display: flex;
      @media (max-width: 667px) {
        justify-content: center;
      }
      .booking-link-text {
        max-width: calc(100% - 62px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
      }
      .link-icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 5px;
        background-image: url("../images/link.svg");
        background-repeat: no-repeat;
      }
    }

    .appointment-types {
      display: flex;
      margin-top: 8px;
      font-size: 11.8px;
      @media (max-width: 1200px) {
        flex-wrap: wrap;
      }
      @media (max-width: 667px) {
        justify-content: center;
        align-items: center;
      }
      span {
        @media (max-width: 1200px) {
          margin-bottom: 10px;
        }
        margin-right: 6px;
        padding: 3px 10px 3px 5px;
        border-radius: 20px;
        display: flex;
        background-color: rgba(209, 214, 243, 0.2);
        border: 0.5px solid $light-grey2-color;
        &.in-person {
          // border: 1px solid $primary-color-hover;
          i {
            background-color: $primary-color-hover;
            svg {
              font-size: 11px;
            }
          }
        }
        &.video-visit {
          // border: 1px solid $primary-color;
          i {
            background-color: $primary-color;
          }
        }
        &.appointment {
          // border: 1px solid $purple-color;
          i {
            background-color: $purple-color;
          }
        }
      }
      i {
        width: 16px;
        height: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 50%;
        margin-right: 5px;
        svg {
          font-size: 8px;
        }
      }
    }

    .hospital-features {
      display: flex;
      margin-top: 12px;
      font-size: 11.8px;
      @media (max-width: 667px) {
        justify-content: center;
      }
      @media (max-width: 1200px) {
        flex-wrap: wrap;
      }
      span {
        @media (max-width: 1200px) {
          margin-bottom: 10px;
        }
        margin-right: 6px;
        padding: 5px 12px;
        border-radius: 20px;
        display: flex;
        background-color: rgba(209, 214, 243, 0.2);
        border: 0.5px solid $light-grey2-color;
        &.feature-icon {
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          font-size: 14px;
          top: 5px;
          left: 10px;
          z-index: 1;
          &.speciality {
            &::before {
              content: "";
              width: 18px;
              height: 16px;
              background-image: url("../images/hospital.svg");
              padding-right: 5px;
              background-repeat: no-repeat;
            }
          }
          &.beds-count {
            // border: 1px solid $primary-color;
            &::before {
              content: "";
              width: 18px;
              height: 16px;
              background-image: url("../images/hospital-bed.svg");
              padding-right: 5px;
              background-repeat: no-repeat;
            }
          }
          &.availability {
            &::before {
              content: "";
              width: 16px;
              height: 16px;
              background-image: url("../images/emergency.svg");
              padding-right: 5px;
              background-repeat: no-repeat;
            }
          }
          &.accreditions {
            // border: 1px solid $purple-color;
            &::before {
              content: "";
              width: 14px;
              height: 16px;
              background-image: url("../images/accredition.svg");
              padding-right: 5px;
              background-repeat: no-repeat;
            }
          }
          &.clinics-count {
            // border: 1px solid $purple-color;
            &::before {
              content: "";
              width: 18px;
              height: 16px;
              background-image: url("../images/clinic.svg");
              padding-right: 5px;
              background-repeat: no-repeat;
            }
          }
          &.patients-count {
            // border: 1px solid $purple-color;
            &::before {
              content: "";
              width: 13px;
              height: 16px;
              background-image: url("../images/user-icon.svg");
              margin-right: 5px;
            }
          }
        }
      }
    }
    .fee-types {
      font-size: 14px;
      font-weight: 700;
      color: $text-color-dark;
      margin-top: 8px;
      span {
        & + span {
          margin-left: 10px;
        }
      }
    }
    .lang-reviews {
      display: flex;
      font-size: 14px;
      margin-top: 8px;
      @media (max-width: 667px) {
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
      }
      a {
        position: relative;
        color: $primary-color;
        padding-left: 15px;
        font-size: 14px;
        &::before {
          content: "•";
          position: absolute;
          left: 5px;
          top: 1px;
          font-size: 11px;
          color: $text-color-dark;
        }
      }
    }
    .search-distance {
      position: relative;
      padding-left: 10px;
      &::before {
        content: "•";
        position: absolute;
        left: 2px;
        top: 0px;
        font-size: 11px;
      }
    }
  }
}

.search-actions {
  width: 190px;
  justify-content: center;
  display: block;
  align-items: center;
  margin: auto;

  .availabile-today {
    color: $green-color;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
  .reviews {
    color: #353949;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
  .review-icon::before {
    content: "";
    width: 18px;
    height: 18px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 20px;
    position: absolute;
    background-image: url("../images/kulcare-icon.svg");
    margin-left: -20px;
  }

  .hospital-booking-link {
    color: #078dff;
  }
  .btn-call {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-call svg {
    margin-right: 6px;
    width: 16px;
    height: 16px;
  }
  .availability-section {
    display: flex;
    justify-content: center;
    .time-slot {
      border: 1px solid $primary-color;
      border-radius: 4px;
      padding: 5px 10px;
      margin: 8px 3px 0px;
      cursor: pointer;
      min-width: 58.5px;
      position: relative;
      &.active {
        color: $primary-color;
      }
      span {
        display: block;
        &.day {
          font-size: 11px;
          text-transform: uppercase;
          text-align: center;
        }
        &.text {
          font-size: 7.3px;
          text-align: center;
        }
      }
    }
  }
  .search-buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    button {
      & + button {
        margin-top: 8px;
      }
    }
  }
}
.search-item-left {
  text-align: center;
}
.outer-slot-discount {
  position: absolute;
  top: -10px;
  background-color: var(--text-color-red);
  color: white;
  font-size: 9px;
  padding: 1px 3px;
  border-radius: 3px;
  &.left {
    top: 80px;
    transform: translateX(-50%);
    @media (max-width: 667px) {
      top: 2px;
    }
  }
}
.doc-search-detail {
  /* width: calc(100% - 90px); */
  width: 100%;
  justify-content: space-between;
}
.doc-info-conatiner {
  display: flex;
  justify-content: space-between;
}
.doc-book-appointment-info {
  display: flex;
  justify-content: space-between;
}
.search-doc-info-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
}

.appt-icon {
  vertical-align: middle;
  margin-right: 6px;
  transform: translateY(2px);
  // color: $primary-color;
}

.text-search-wrap {
  width: 30%;
  margin-left: 5px;
  position: relative;
  & > div {
    display: block !important;
  }
  input {
    border: 1px solid $border-color;
    padding: 5px 10px;
    border-radius: $controls-radius;
    height: 35px;
    width: 100%;
    &:focus {
      border-color: $primary-color;
      outline: none;
    }
  }
}
.autocomplete-menu {
  position: absolute;
  width: 100%;
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: $controls-radius;
}
.autocomplete-item {
  padding: 5px 10px;
  color: $text-color-dark;
  cursor: pointer;
}
.item-highlighted {
  background-color: $app-background;
}
.search-filter-content-wrap {
  padding: 15px;
  background-color: $app-background;
}
.filters-content {
  display: flex;
  width: 100%;
  align-items: center;
  @media (max-width: 667px) {
    display: none;
  }
  .filter-modal {
    @media (max-width: 667px) {
      display: block;
    }
  }
}

.filter-block {
  & + .filter-block {
    margin-left: 8px;
    @media (max-width: 667px) {
      margin-left: 0;
      margin-top: 5px;
    }
  }
  &.sz-40 {
    width: 40%;
  }
  width: 20%;
  min-width: 140px;
  @media (max-width: 667px) {
    width: 100%;
  }

  .filter-section {
    display: block;
  }
  .select-search__input {
    padding-left: 30px;
    padding-right: 25px;
  }
  .select-search__value {
    &::after {
      right: 12px;
    }
  }
  &.speciality {
    &.selected {
      .select-search__value {
        input {
          color: $primary-color;
        }
        &::before {
          background-image: url("../images/speciality-selected.svg");
        }
        &::after {
          border-color: $primary-color;
        }
      }
    }
    .select-search__value {
      &::before {
        content: "";
        position: absolute;
        background-image: url("../images/speciality-empty.svg");
        background-size: cover;
        background-position: center;
        width: 10px;
        height: 12px;
        top: 8px;
        left: 10px;
      }
    }
  }

  &.appointment-type {
    &.selected {
      &.virtual {
        .select-search__value {
          &::before {
            background-image: url("../images/appointment-selected.svg");
          }
        }
      }
      &.in_person {
        .select-search__value {
          &::before {
            background-image: url("../images/speciality-selected.svg");
            width: 10px;
            height: 12px;
            top: 8px;
            left: 10px;
          }
        }
      }
      .select-search__value {
        input {
          color: $primary-color;
        }

        &::after {
          border-color: $primary-color;
        }
      }
    }
    .select-search__value {
      &::before {
        content: "";
        position: absolute;
        background-image: url("../images/appointment-empty.svg");
        background-size: cover;
        background-position: center;
        width: 14px;
        height: 10px;
        top: 10px;
        left: 10px;
      }
    }
  }
  &.language {
    &.selected {
      .select-search__value {
        input {
          color: $primary-color;
        }
        &::before {
          background-image: url("../images/language-selected.svg");
        }
        &::after {
          border-color: $primary-color;
        }
      }
    }
    .select-search__value {
      &::before {
        content: "";
        position: absolute;
        background-image: url("../images/language-empty.svg");
        background-size: cover;
        background-position: center;
        width: 14px;
        height: 14px;
        top: 7px;
        left: 10px;
      }
    }
  }
  &.location {
    &.selected {
      .select-search__value {
        input {
          color: $primary-color;
        }
        &::before {
          background-image: url("../images/location-selected.svg");
        }
        &::after {
          border-color: $primary-color;
        }
      }
    }
    .select-search__value {
      &::before {
        content: "";
        position: absolute;
        background-image: url("../images/location-icon.svg");
        background-size: contain;
        background-position: center;
        width: 14px;
        height: 13px;
        top: 7px;
        left: 8px;
        background-repeat: no-repeat;
      }
    }
  }
}

.filter-toggler {
  display: flex;
  font-size: 12px;
  align-items: center;
  position: relative;
  svg {
    margin-right: 2px;
  }
  .filters-count {
    left: -6px;
    position: absolute;
    background-color: $color-red;
    color: white;
    display: inline-block;
    padding: 2px 4px;
    font-size: 8px;
    border-radius: 50%;
    top: -10px;
  }
}
.map-marker {
  transition: all 0.3s 0s;
  svg {
    color: $primary-color;
    font-size: 40px;
  }
  &.highlighted-marker {
    transform: scale(1.2);
  }
}
.left-info-section {
  width: calc(100% - 150px);
}
.sorting-block {
  position: relative;
  max-width: 75px;
  align-items: center;
  .sort-heading {
    font-size: 10px;
    color: #707070;
    width: 45px;
  }
  .sort-select {
    width: 66px;
  }
  .select-search {
    line-height: 18px;
    height: 18px;
  }
  .select-search__input {
    border: none;
    padding-left: 0;
    padding-right: 18px;
    height: 18px !important;
  }
  .select-search__select {
    min-width: 100px;
    left: auto !important;
    right: 0;
  }
  .select-search__value::after {
    right: 5px;
  }
}

.rc-dialog.booking-modal {
  .rc-dialog-close {
    z-index: 1;
  }
  @media (min-width: 560px) {
    width: 500px;
    margin: 20px auto;
  }
}

.search-right {
  @media (max-width: 667px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .select-search.small .select-search__input {
    width: 80px;
  }
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  .btn-apply {
    display: none;
    @media (max-width: 667px) {
      position: relative;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      border: 1px solid transparent;
      cursor: pointer;
      width: 150px;
      height: 32px;
      padding: 0 15px;
      font-size: 14px;
      border-radius: 4px;
      margin-right: 5px;
      color: #fff;
      background-color: #078dff;
    }
  }
  .btn-cancel {
    display: none;
    @media (max-width: 667px) {
      position: relative;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      border: 1px solid #d1d6f3;
      cursor: pointer;
      width: 150px;
      height: 32px;
      padding: 0 15px;
      font-size: 14px;
      border-radius: 4px;
      color: #000;
      background-color: #fff;
      margin-left: 5px;
    }
  }
}
.filter-reset-btn {
  @media (max-width: 667px) {
    display: none;
  }
}
.search-left {
  &.modal {
    @media (max-width: 667px) {
      display: block;
    }
  }
  .filter-search {
    display: none;
    @media (max-width: 667px) {
      display: flex;
    }
    .filter-icon {
      @media (max-width: 667px) {
        width: 18px;
        height: 18px;
        margin-left: 2px;
        color: #7f7f7f;
        margin-right: 3px;
        justify-content: start;
        align-self: start;
        font-size: 14px;
      }
    }
  }
}

.pr-5 {
  padding-right: 5px;
}
.walking-appt-arrow.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
.doctor-listing-wrapper {
  width: 992px;
  margin: 0px auto 10px;
  background: #ffffff;
  box-shadow: 0px 5px 25px #ebeef1;
  border-radius: 6px;

  @media (max-width: 1100px) {
    width: 98%;
    max-width: 992px;
  }
}
.react-tags {
  position: relative;
  padding: 3px 0 3px 6px;
  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
  font-size: var(--base-font-size);
  box-shadow: none;
  min-height: var(--normal-input-height);
  border: 1px solid var(--light-grey-color);
  border-radius: var(--controls-border-radius);
  background-color: white;
}
.react-tags.large {
  min-height: 93px;
}
.react-tags.is-focused {
  border-color: var(--text-color-primary);
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 6px 3px 0;
  padding: 3px 6px 3px 24px;
  border: 1px solid var(--light-grey-color);
  border-radius: var(--controls-border-radius);
  background: #f8faff;
  font-size: var(--label-font-size);
  line-height: inherit;
  color: var(--text-color-dark);
  font-weight: 600;
  position: relative;
}

.react-tags__selected-tag:after {
  content: "\2715";
  color: var(--bluey-grey);
  margin-left: 8px;
  font-size: 10px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: var(--light-grey-color);
  background: rgba(209, 214, 243, 0.2);
}
.react-tags__selected-tag::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--text-color-primary);
  position: absolute;
  left: 8px;
  top: 6px;
}
.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 2px 2px;
  margin-bottom: 4px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
  height: 23px;
  line-height: 23px;
}
.modal-sep {
  width: 100%;
  height: 1px;
  background-color: #d1d6f3;
}
.justify-center {
  justify-content: center;
}
.width-130 {
  width: 130px;
}
.text-center {
  text-align: center;
}
.full-width.ant-select,
.full-width.ant-select .ant-select-selection {
  width: 100%;
}
.ant-select-sm .ant-select-selection--single {
  height: 28px;
}
.ant-select-sm .ant-select-selection__rendered {
  line-height: 25px;
}
.ant-select-selection {
  border: 1px solid #d1d6f3;
}
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  color: rgb(117, 117, 117);
}
.ant-select-open .ant-select-selection {
  box-shadow: none;
}
.ant-select-open .ant-select-selection {
  border-color: var(--text-color-primary);
}
.ant-select-dropdown-menu-item {
  border-bottom: 1px solid #f5f5f5;
}
.ant-select-dropdown-menu-item-active {
  background-color: #f5f5f5 !important;
}
.ant-select.with-icon .ant-select-selection {
  padding-left: 20px;
}
.ant-select.with-icon.location .ant-select-selection:before {
  content: "";
  background-image: url("../images/location-icon.svg");
  background-size: cover;
  width: 13px;
  height: 16px;
  position: absolute;
  top: 5px;
  left: 7px;
}
.small_input .ant-input {
  height: 28px;
  color: var(--text-color-dark);
}
.small_input .ant-input:focus {
  outline: none;
}
.select-search.small .select-search__input::-webkit-input-placeholder {
  color: var(--text-color-dark);
}
.ant-input::-webkit-input-placeholder {
  color: var(--bluey-grey);
}
.ant-input {
  border: 1px solid #d1d6f3;
}
